import queryString from 'query-string';
import { matchPath } from "react-router";
import {deepGet} from "services/helpers.service";

class Navigation{
        
    static getURLParam(props=null,key=""){
        console.assert(props.match != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        return props.match.params[key];
    }

    static getQueryObject(props=null){
        console.assert(props.location != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        let parsedQueryString = queryString.parse(props.location.search);
        return parsedQueryString;
    }

    static getQueryParam(props=null,key=""){
        console.assert(props.location != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        let parsedQueryString = Navigation.getQueryObject(props);
        return parsedQueryString[key];
    }

    static setQueryParams(props=null,queryObject=null){
        console.assert(props.history != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        props.history.push({
            search:queryString.stringify(queryObject),
        });
        return;
    }

    static isInSubPath(props=null,parentPath=""){
        console.assert(props.location != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        let currentPath = props.location.pathname;
        let subString = currentPath.substring(0,parentPath.length);
        return subString === parentPath;
    }

    static getPathTitle(props=null,defaultTitle=""){
        console.assert(props.location != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        let currentPath = props.location.pathname;
        let pathData = Navigation.getPathData(currentPath);
        return pathData ? pathData.title : defaultTitle;
    }

    static getPathData(currPath){
        // loop through path data keys and get exact match
        for (let [path, pathInfo] of Object.entries(Navigation.pathData)) {
            let match = matchPath(currPath,{
                path:path,
                exact:true,
                strict:false,
            });
            if(match){
                return pathInfo;
            }
        }
        return null; 
    }

    static goBack(props=null){
        console.assert(props.history != null, "Must ensure component wrapped in 'withRouter' to allow URL access.");
        props.history.goBack();
    }

    static paths = {
        root:"/",
        auth:"/auth",
        login:"/auth/login",
        register:"/auth/register",
        forgotPassword:"/auth/forgot-password",
        app:"/app",
        // orders:"/app/orders",
        // orderDetail:"/app/orders/:id",
        home:"/app/home",
        memorials:"/app/memorials",
        sales:"/app/sales",
        dealerDetail:"/app/dealers/:id",
        dealerEdit:"/app/dealers/:id/edit",
        dealerCreate:"/app/dealers/create",
        orders:"/app/orders",
        orderDetail:"/app/orders/:id",
        orderEdit:"/app/orders/:id/edit",
        orderCreate:"/app/orders/create",
        chart:"/app/charts",
        userDetail:"/app/users/:id",
        userEdit:"/app/users/:id/edit",
        userCreate:"/app/users/create",
        manageAccount:"/app/manage-account",
    };
    
    static generateDealerDetailPath(id){
        return `/app/dealers/${id}`;
    }
    
    static generateDealerEditPath(id){
        return `/app/dealers/${id}/edit`;
    }

    static generateOrderDetailPath(id){
        return `/app/orders/${id}`;
    }
    
    static generateOrderEditPath(id){
        return `/app/orders/${id}/edit`;
    }

    static generateUserDetailPath(id){
        return `/app/users/${id}`;
    }
    
    static generateUserEditPath(id){
        return `/app/users/${id}/edit`;
    }

    static pathData={
        [Navigation.paths.home]:{
            title:"Home",
        },
        [Navigation.paths.sales]:{
            title:"Sales",
        },
        [Navigation.paths.memorials]:{
            title:"Memorials",
        },
        [Navigation.paths.chart]:{
            title:"Charts",
        },
        [Navigation.paths.dealerCreate]:{
            title:"Add Dealer",
        },
        [Navigation.paths.dealerEdit]:{
            title:"Edit Dealer",
        },
        [Navigation.paths.orderCreate]:{
            title:"Add Order",
        },
        [Navigation.paths.orderEdit]:{
            title:"Edit Order",
        },
        [Navigation.paths.userCreate]:{
            title:"Create User",
        },
        [Navigation.paths.manageAccount]:{
            title:"Account",
        },
    }

}

export default Navigation;