import React from 'react';
import PropTypes from 'prop-types';
import HTTPService from "services/http.service";
import {currencyFormat} from "services/helpers.service";
import {
  FlexColumnStartStretch,
  FlexRowCenterStretch,
  DataTable,
} from "components";
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";
import { 
  Container,
  Grid,
  Chip,
  Slider,
  Typography,
  Divider,
  Tabs,
  Tab,
  TabPanel,
  Button,
 } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LanguageIcon from '@material-ui/icons/Language';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine, Line } from '@nivo/line'
import PieChartIcon from '@material-ui/icons/PieChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import AutoSizer from "react-virtualized-auto-sizer";
import Theme from "theme/main.theme.js";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CasinoIcon from '@material-ui/icons/Casino';

class ChartView extends React.Component{
  static propTypes={
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:false,
      // data:data,
      serviceRevenue:80000,
      productRevenue:50000,
      otherRevenue:8000,
      tab:0,
      lineData:[],
      originalLineData:[],
      daysBack:10,
      minY:0,
    };

    this.pullData = this.pullData.bind(this);
    this.generateLineData = this.generateLineData.bind(this);
    this.setDaysBack = this.setDaysBack.bind(this);
  }
  
  componentDidMount(){
    this.pullData();
    this.generateLineData();
  }

  componentWillUnmount(){
  }

  async pullData(){
    this.setState({loading:true});
    
    // let users = await HTTPService.getUsers(this.props.history);
    // let data = users;
    // console.log(data);

    this.setState({loading:false});
  }

  generateLineData(generate=true){
    let newLineData=this.state.originalLineData;
    if(generate){
      let now = new Date();
      let t1 = new Date();
      t1.setDate(now.getDate()-1);
      t1.setHours(18,0,0,0);
      let t2 = new Date();
      t2.setDate(now.getDate()-2);
      t2.setHours(18,0,0,0);
      let t3 = new Date();
      t3.setDate(now.getDate()-3);
      t3.setHours(18,0,0,0);
      let t4 = new Date();
      t4.setDate(now.getDate()-4);
      t4.setHours(18,0,0,0);
      let t5 = new Date();
      t5.setDate(now.getDate()-5);
      t5.setHours(18,0,0,0);
      let t6 = new Date();
      t6.setDate(now.getDate()-6);
      t6.setHours(18,0,0,0);
      let t7 = new Date();
      t7.setDate(now.getDate()-7);
      t7.setHours(18,0,0,0);
      let t8 = new Date();
      t8.setDate(now.getDate()-8);
      t8.setHours(18,0,0,0);
      let t9 = new Date();
      t9.setDate(now.getDate()-9);
      t9.setHours(18,0,0,0);
      let t10 = new Date();
      t10.setDate(now.getDate()-10);
      t10.setHours(18,0,0,0);
      let t11 = new Date();
      t11.setDate(now.getDate()-11);
      t11.setHours(18,0,0,0);
      let t12 = new Date();
      t12.setDate(now.getDate()-12);
      t12.setHours(18,0,0,0);
      let t13 = new Date();
      t13.setDate(now.getDate()-13);
      t13.setHours(18,0,0,0);
      let t14 = new Date();
      t14.setDate(now.getDate()-14);
      t14.setHours(18,0,0,0);
      let t15 = new Date();
      t15.setDate(now.getDate()-15);
      t15.setHours(18,0,0,0);
      let t16 = new Date();
      t16.setDate(now.getDate()-16);
      t16.setHours(18,0,0,0);
      let t17 = new Date();
      t17.setDate(now.getDate()-17);
      t17.setHours(18,0,0,0);
      let t18 = new Date();
      t18.setDate(now.getDate()-18);
      t18.setHours(18,0,0,0);
      let t19 = new Date();
      t19.setDate(now.getDate()-19);
      t19.setHours(18,0,0,0);
      let t20 = new Date();
      t20.setDate(now.getDate()-20);
      t20.setHours(18,0,0,0);
      newLineData=[
        {
          "id": "Revenue",
          "color": "hsl(104, 70%, 50%)",
          "data": [
            {x:t20,y:randInt(1000)},
            {x:t19,y:randInt(1000)},
            {x:t18,y:randInt(1000)},
            {x:t17,y:randInt(1000)},
            {x:t16,y:randInt(1000)},
            {x:t15,y:randInt(1000)},
            {x:t14,y:randInt(1000)},
            {x:t13,y:randInt(1000)},
            {x:t12,y:randInt(1000)},
            {x:t11,y:randInt(1000)},
            {x:t10,y:randInt(1000)},
            {x:t9,y:randInt(1000)},
            {x:t8,y:randInt(1000)},
            {x:t7,y:randInt(1000)},
            {x:t6,y:randInt(1000)},
            {x:t5,y:randInt(1000)},
            {x:t4,y:randInt(1000)},
            {x:t3,y:randInt(1000)},
            {x:t2,y:randInt(1000)},
            {x:t1,y:randInt(1000)},
            {x:now,y:randInt(1000)},
          ]
        },
      ];
      this.setState({
        originalLineData:newLineData,
      });
    }
    
    let data = newLineData[0]["data"].slice(newLineData[0]["data"].length-1-this.state.daysBack);
    console.log(data);
    let minY = Math.min(...data.map((point)=>point.y));
    console.log(minY);
    console.log(newLineData);
    let updated = [...newLineData];
    updated[0] = {...updated[0],"data":[...data]};
    this.setState({
      lineData:updated,
      minY:minY,
    });
  }

  setDaysBack(days){
    if (days !== null) {
      this.setState({
        daysBack:days,
      },()=>this.generateLineData(false));
    }
  }

  render(){

    let data = [
      {
        "id": "Services",
        "label": "Service Revenue",
        "value": this.state.serviceRevenue,
        "color": "hsl(144, 70%, 50%)"
      },
      {
        "id": "Products",
        "label": "Product Revenue",
        "value": this.state.productRevenue,
        "color": "hsl(2, 70%, 50%)"
      },
      {
        "id": "Other",
        "label": "Other Revenue",
        "value": this.state.otherRevenue,
        "color": "hsl(13, 70%, 50%)"
      },
    ];


    return (
      <FlexColumnStartStretch style={{
        // maxWidth:"100%",
      }}>
        {/* <button>hi</button>       */}
        <Tabs 
        value={this.state.tab} 
        onChange={(event, newValue)=>this.setState({tab:newValue})} 
        aria-label="simple tabs example"
        style={{
          alignSelf:"center"
        }}
        >
          <Tab label="Line"  icon={<TimelineIcon />}/>
          <Tab label="Pie"  icon={<PieChartIcon />}/>
          {/* <Tab label="Item Three" /> */}
        </Tabs> 
        <div style={{ 
          display:this.state.tab===0?"block":"none",
        }} >
                 
          <FlexColumnStartStretch >  
          <FlexRowCenterStretch 
            style={{
              padding:"20px 40px",
            }}
          >
            <Button 
            variant="contained" 
            color="primary"
            onClick={this.generateLineData}
            startIcon={<CasinoIcon/>}
            >
              Randomize
            </Button>
            <ToggleButtonGroup
              style={{
                marginLeft:"auto",
              }}
              value={this.state.daysBack}
              exclusive
              onChange={(e,newValue)=>this.setDaysBack(newValue)}
              aria-label="text alignment"
            >
              <ToggleButton value={3} aria-label="3"
                style={{
                    backgroundColor:this.state.daysBack===3?Theme.primaryColor:"inherit",
                    color:this.state.daysBack===3?"white":"inherit",
                }}
              >
                {/* <FormatAlignLeftIcon /> */}
                3 Days
              </ToggleButton>
              <ToggleButton value={5} aria-label="5"
                style={{
                    backgroundColor:this.state.daysBack===5?Theme.primaryColor:"inherit",
                    color:this.state.daysBack===5?"white":"inherit",
                }}
              >
                {/* <FormatAlignCenterIcon /> */}
                5 Days
              </ToggleButton>
              <ToggleButton value={10} aria-label="10"
                style={{
                    backgroundColor:this.state.daysBack===10?Theme.primaryColor:"inherit",
                    color:this.state.daysBack===10?"white":"inherit",
                }}
              >
                {/* <FormatAlignRightIcon /> */}
                10 Days
              </ToggleButton>
              <ToggleButton value={20} aria-label="20" 
                style={{
                    backgroundColor:this.state.daysBack===20?Theme.primaryColor:"inherit",
                    color:this.state.daysBack===20?"white":"inherit",
                }}
              >
                {/* <FormatAlignJustifyIcon /> */}
                20 Days
              </ToggleButton>
            </ToggleButtonGroup>
            
          </FlexRowCenterStretch>                
            
            {/*AutoSizer solves bug: https://github.com/plouc/nivo/issues/411 */}
            <AutoSizer style={{ 
              // display:this.state.tab===0?"block":"none",
              // width:'100%',
              height:250,
              }}>
              {({ height, width }) => (
                            
                <Line
                  height={height}
                  width={width}
                  data={this.state.lineData}
                  xFormat={(value)=>dateFormat(value)}
                  yFormat={(value)=>currencyFormat(value)}
                  margin={{ top: 20, right: 110, bottom: 50, left: 110 }}
                  xScale={{
                    type:"point",
                    // type:"linear",
                    // type: 'time',
                    // useUtc:false,
                    // precision: 'day',
                    // reverse: true,
                  }}
                  yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                  curve="catmullRom"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                      orient: 'bottom',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      // legend: 'transportation',
                      legendOffset: 36,
                      legendPosition: 'middle',
                      // format:(value)=>currencyFormat(value),
                      // format: '%b %d',
                      tickValues: 'every day',
                      // legend: 'time scale',
                      // legendOffset: -12,
                      format:(value)=>dateFormat(value),
                  }}
                  axisLeft={{
                      orient: 'left',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendOffset: -80,
                      legendPosition: 'middle',
                      legend:"Revenue",
                      format:(value)=>currencyFormat(value),
                  }}
                  enableGridX={true}
                  enableGridY={false}
                  enableArea={true}
                  areaBaselineValue={this.state.minY}
                  colors={[Theme.secondaryColor]}
                  lineWidth={3}
                  enablePoints={false}
                  pointSize={10}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  useMesh={true}
                  legends={[
                      {
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          effects: [
                              {
                                  on: 'hover',
                                  style: {
                                      itemBackground: 'rgba(0, 0, 0, .03)',
                                      itemOpacity: 1
                                  }
                              }
                          ]
                      }
                  ]}
                />
                )}
              </AutoSizer>
            </FlexColumnStartStretch >
          </div>

          <div style={{
            display:this.state.tab===1?"block":"none",
          }}>
            
            <FlexRowCenterStretch style={{
              padding:"20px 0px",
              // display:this.state.tab==1?"none":"none",
            }}>
              <FlexColumnStartStretch style={{
                // padding:"40px",
                flexGrow:1,
                maxWidth:475,
              }}>

                <Typography variant="h1" style={{
                  textAlign:"center",
                }}>
                  Revenue Breakdown
                </Typography>
                <Typography variant="h4" style={{
                  marginTop:10,
                  textAlign:"center",
                }}>
                  {currencyFormat(this.state.serviceRevenue+this.state.productRevenue+this.state.otherRevenue)}
                </Typography>

                <div style={{
                  height:350,
                  width:350,
                  alignSelf:"center",
                }}>
                    <ResponsivePie
                      data={data}
                      margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
                      cornerRadius={5}
                      // colors={["#1c0a3c","#1c0a3cba","#1c0a3c8a"]}
                      colors={{scheme:"purple_orange"}}
                      borderWidth={1}
                      borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                      enableRadialLabels={false}
                      radialLabelsSkipAngle={10}
                      radialLabelsTextXOffset={6}
                      radialLabelsTextColor="#333333"
                      radialLabelsLinkOffset={0}
                      radialLabelsLinkDiagonalLength={16}
                      radialLabelsLinkHorizontalLength={24}
                      radialLabelsLinkStrokeWidth={1}
                      radialLabelsLinkColor={{ from: 'color' }}
                      sliceLabel={function(e){return currencyFormat(e.value)}}
                      slicesLabelsSkipAngle={10}
                      slicesLabelsTextColor="#ffffff"
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      legends={[
                          {
                              anchor: 'bottom',
                              direction: 'row',
                              translateY: 56,
                              itemWidth: 100,
                              itemHeight: 18,
                              itemTextColor: '#999',
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemTextColor: '#000'
                                      }
                                  }
                              ]
                          }
                      ]}
                  />

                </div>
              </FlexColumnStartStretch>
              <FlexColumnStartStretch style={{
                flexGrow:1,
                padding:"20px 20px",
              }}>
                <Typography id="continuous-slider" gutterBottom>
                  Service Revenue
                </Typography>
                <Slider 
                style={{
                  marginBottom:20,
                }}
                value={this.state.serviceRevenue} 
                onChange={(event, newValue)=>this.setState({serviceRevenue:newValue})} 
                aria-labelledby="continuous-slider" 
                min={0}
                max={200000}
                />
                {/* <Divider/> */}
                <Typography id="continuous-slider" gutterBottom>
                  Product Revenue
                </Typography>
                <Slider 
                style={{
                  marginBottom:20,
                }}
                value={this.state.productRevenue} 
                onChange={(event, newValue)=>this.setState({productRevenue:newValue})} 
                aria-labelledby="continuous-slider" 
                min={0}
                max={200000}
                />
                <Typography id="continuous-slider" gutterBottom>
                  Other Revenue
                </Typography>
                <Slider 
                style={{
                  marginBottom:20,
                }}
                value={this.state.otherRevenue} 
                onChange={(event, newValue)=>this.setState({otherRevenue:newValue})} 
                aria-labelledby="continuous-slider" 
                min={0}
                max={200000}
                />
              </FlexColumnStartStretch>
            </FlexRowCenterStretch>
          </div>
      </FlexColumnStartStretch>
    );
  }
}


export default withRouter(ChartView);


function randInt(max){
  return Math.floor(Math.random() * Math.floor(max));
}


function dateFormat(date){  
  return date.toLocaleDateString('en-US', {
    // weekday: 'long', 
    // year: 'numeric', 
    month: 'long', 
    day: 'numeric',
  });
}
