import React from 'react';
import PropTypes from 'prop-types';
import { 
  withRouter,
} from "react-router-dom";
import {CustomForm, CRUDForm} from "./forms.component.js";
import {
  Payment,
} from "./stripe.component.js";
import HTTPService from "services/http.service.js";
import {Toast} from "services/toast.service";
import JWT from "services/jwt.service.js";
import CurrentUser from "services/current-user.service";
import {currencyFormat, percentFormat} from "services/helpers.service";
import Navigation from "services/navigation.service.js";
import {
  FlexRowCenterCenter,
  FlexColumnStartStretch,
  FlexRowBetweenCenter,
  FlexRowBetweenStretch,
  FlexRowStartStretch,
  FlexRowEndStretch,
  FlexColumnBetweenStretch,
} from "./flex.component.js";
import {
  CustomInput, 
  EmailInput, 
  PasswordInput, 
  InputErrorMessage, 
  CustomTextArea, 
  CustomSelect,
  StateSelectInput,
  CustomNumberInput,
  CustomPasswordInput,
  CustomTelephoneInput,
  CustomTextInput,
  CustomUrlInput,
  CustomZipcodeInput,
  CustomDateInput,
  CustomDateTimeInput,
  CustomEmailInput,
  CustomCheckboxInput, 
  CustomCurrencyInput, 
} from "./inputs.component.js";
import { 
  Button, 
  CircularProgress,
  Typography,
  Grid,
  Card,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import {
  TotalsComponent,
} from "./totals.component.js";
import GetAppIcon from '@material-ui/icons/GetApp';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Theme from 'theme/main.theme.js';

class OrderForm extends React.Component{
    spacing=10;
    rowSpacing=20;

    static propTypes={
      history:PropTypes.object.isRequired,
      edit:PropTypes.bool,
      editObjectId:PropTypes.string,
      initialData:PropTypes.object,
      showDelete:PropTypes.bool,
      deletionFunction:PropTypes.func,
      deletionSuccessRedirectFunction:PropTypes.func,
      deleteDialogTitle:PropTypes.string,
      deleteDialogBody:PropTypes.string,
    };

    
    constructor(props) {
        super(props);
        this.state={
          orderId:null,
          loading:false,
          validateAlways:true,
          showDeleteDialog:false,
          // dealerOptions:[],
          fieldNameList:[
            "subscriber",
            "dealer",
            "builder",
            "deliverer",
            "sale_date",
            "salesman",
            "first_name",
            "last_name",
            "mailing_street_address",
            "mailing_city",
            "mailing_state",
            "mailing_zipcode",
            "billing_street_address",
            "billing_city",
            "billing_state",
            "billing_zipcode",
            "primary_phone",
            "secondary_phone",
            "email",
            "building_size",
            "model",
            "exterior",
            "build_type",
            "has_3D",
            "has_hand_drawing",
            "build_notes",
            "payment_method",
            "payment_term",
            "side_wall_color",
            "roof_color",
            "trim_color",
            "inventory_number",
            "other_notes",
            "order_additions",
            "amount_received",
            "stripe_id",
            "building_price",
            "tax_rate",
            "additional_deposit",
          ],
          subscriber:{
            value:CurrentUser.getUserFromStorage().organization.parent || CurrentUser.getUserFromStorage().organization.id,
            isValid:true,
          },
          dealer:{
            value: CurrentUser.getUserFromStorage().user.id,
            isValid:true,
          },
          builder:{
            value:null,
            isValid:true,
          },
          deliverer:{
            value:null,
            isValid:true,
          },
          sale_date:{
            value:new Date(),
            isValid:true,
          },
          salesman:{
            value:"",
            isValid:true,
          },
          first_name:{
            value:"",
            isValid:true,
          },
          last_name:{
            value:"",
            isValid:true,
          },
          mailing_street_address:{
            value:"",
            isValid:true,
          },
          mailing_city:{
            value:"",
            isValid:true,
          },
          mailing_state:{
            value:"",
            isValid:true,
          },
          mailing_zipcode:{
            value:"",
            isValid:true,
          },
          billing_street_address:{
            value:"",
            isValid:true,
          },
          billing_city:{
            value:"",
            isValid:true,
          },
          billing_state:{
            value:"sd",
            isValid:true,
          },
          billing_zipcode:{
            value:"",
            isValid:true,
          },
          primary_phone:{
            value:"",
            isValid:true,
          },
          secondary_phone:{
            value:"",
            isValid:true,
          },
          email:{
            value:"",
            isValid:true,
          },
          building_size:{
            value:"",
            isValid:true,
          },
          model:{
            value:"",
            isValid:true,
          },
          exterior:{
            value:"",
            isValid:true,
          },
          build_type:{
            value:"ON-LOT-USED",
            isValid:true,
          },
          has_3D:{
            value:false,
            isValid:true,
          },
          has_hand_drawing:{
            value:false,
            isValid:true,
          },
          build_notes:{
            value:"",
            isValid:true,
          },
          payment_method:{
            value:"",
            isValid:true,
          },
          payment_term:{
            value:"UPFRONT",
            isValid:true,
          },
          side_wall_color:{
            value:"",
            isValid:true,
          },
          roof_color:{
            value:"",
            isValid:true,
          },
          trim_color:{
            value:"",
            isValid:true,
          },
          inventory_number:{
            value:"",
            isValid:true,
          },
          other_notes:{
            value:"",
            isValid:true,
          },          
          order_additions:{
            value:[],
            isValid:true,
          },
          amount_received:{
            value:0,
            isValid:true,
          },
          stripe_id:{
            value:"",
            isValid:true,
          },
          building_price:{
            value:0,
            isValid:true,
          },
          tax_rate:{
            value:parseFloat(CurrentUser.getUserFromStorage().organization.sales_tax) || 0,
            isValid:true,
          },
          additional_deposit:{
            value:0,
            isValid:true,
          },
          toCharge:"",
        };
        // Set initial values
        if(props.initialData){
          this.state.orderId=props.initialData.id;
          this.state.fieldNameList.forEach(field=>{
            if(field==="subscriber" || field==="dealer" || field==="builder" || field==="deliverer"){
              this.state[field].value=props.initialData[field] ? props.initialData[field].id:null;
            }else{
              this.state[field].value=props.initialData[field];
            }
          });
        }

        // this.pullOrgs = this.pullOrgs.bind(this);
        this.getAdditionsTotal = this.getAdditionsTotal.bind(this);
        this.getTotals = this.getTotals.bind(this);
        this.downloadFilledRTOContract = this.downloadFilledRTOContract.bind(this);
        this.downloadFilledACHContract = this.downloadFilledACHContract.bind(this);        
        this.createPDFFormMap = this.createPDFFormMap.bind(this);
        this.handleSubmission = this.handleSubmission.bind(this);
        this.handleDocusign = this.handleDocusign.bind(this);
        this.formatJson = this.formatJson.bind(this);
        this.handleDeletion = this.handleDeletion.bind(this);

    }

    componentDidMount(){
      // this.pullOrgs();

    }
  
    componentWillUnmount(){
    }

    handleChange(key,value,isValid) {
      this.setState((state,props)=>({ 
          [key]:{
              ...state[key],
              value:value,
              isValid:isValid,  
          }
      }),this.checkAllFieldsValid);
      return;
    }


    async handleDeletion() {
        this.setState({ 
            loading:true,
            showDeleteDialog:false,
        });

        // push to url
        try {
            let resp = await this.props.deletionFunction(this.props.editObjectId,this.props.history);
            Toast.warning("Deleted successfully");

            // Redirect
            if(this.props.deletionSuccessRedirectFunction){
                this.props.deletionSuccessRedirectFunction();
            }
        } catch (error) {
            Toast.error("Unable to Delete. Please try again later.");
        }
        
        this.setState({ 
            loading:false,
        });
        return;
    }

    checkAllFieldsValid(){
      let allValid=true;
      for (let index = 0; index < this.state.fieldNameList.length; index++) {
          const fieldName = this.state.fieldNameList[index];
          if(!this.state[fieldName].isValid){
              allValid=false;
          }
      }
      this.setState({        
          allFieldsValid: allValid,
      });
      return;
    }

    formatJson(){
      let json = {};
      for (let index = 0; index < this.state.fieldNameList.length; index++) {
          const fieldName = this.state.fieldNameList[index];
          json[fieldName]=this.state[fieldName].value;
      }
      return json;
    }

    // async pullOrgs(){
    //   this.setState({loading:true});
  
    //   let organizations = await HTTPService.getOrganizations(this.props.history);
  
    //   // filter out non-dealers
    //   let dealerOptions = organizations.filter((org)=>org.is_dealer);
  
    //   this.setState({dealerOptions:dealerOptions,loading:false});
    // }




    getAdditionsTotal(){
      return this.state["order_additions"].value.reduce((accum,curr)=>{
        return accum+(curr.quantity*curr.unit_price);
      },0);
    }

    getTotals(){
      let buildingPrice = Number(this.state["building_price"].value || 0 );
      let additionsTotal = Number(this.getAdditionsTotal() || 0 );
      let preTaxTotal = buildingPrice+additionsTotal;
      
      let additionalDeposit = Number(this.state["additional_deposit"].value || 0 );      
      let cashPriceOfLeased = preTaxTotal - additionalDeposit;
      let rto24PreTax = cashPriceOfLeased/16.8;
      let rto36PreTax = cashPriceOfLeased/21.6;
      let rto48PreTax = cashPriceOfLeased/24;
      
      let salesTaxRate =  Number(this.state["tax_rate"].value || 0 );

      let tax = preTaxTotal*salesTaxRate;
      let totalDue = preTaxTotal+tax;

      let rto24Tax = rto24PreTax*salesTaxRate;
      let rto36Tax = rto36PreTax*salesTaxRate;
      let rto48Tax = rto48PreTax*salesTaxRate;

      let rto24TotalDue = rto24PreTax+rto24Tax;
      let rto36TotalDue = rto36PreTax+rto36Tax;
      let rto48TotalDue = rto48PreTax+rto48Tax;

      let rto24SecurityDeposit = rto24PreTax+additionalDeposit;
      let rto36SecurityDeposit = rto36PreTax+additionalDeposit;
      let rto48SecurityDeposit = rto48PreTax+additionalDeposit;

      let rto24TaxOnSecurityDeposit = rto24SecurityDeposit*salesTaxRate;
      let rto36TaxOnSecurityDeposit = rto36SecurityDeposit*salesTaxRate;
      let rto48TaxOnSecurityDeposit = rto48SecurityDeposit*salesTaxRate;

      let rto24DueToday = rto24SecurityDeposit+rto24TaxOnSecurityDeposit;
      let rto36DueToday = rto36SecurityDeposit+rto36TaxOnSecurityDeposit;
      let rto48DueToday = rto48SecurityDeposit+rto48TaxOnSecurityDeposit;

      let rto24TotalOwnershipCost = rto24PreTax*24;
      let rto36TotalOwnershipCost = rto36PreTax*36;
      let rto48TotalOwnershipCost = rto48PreTax*48;

      return{
        buildingPrice,
        additionsTotal,
        preTaxTotal,
        additionalDeposit,
        cashPriceOfLeased,
        rto24PreTax,
        rto36PreTax,
        rto48PreTax,
        salesTaxRate,
        tax,
        rto24Tax,
        rto36Tax,
        rto48Tax,
        totalDue,
        rto24TotalDue,
        rto36TotalDue,
        rto48TotalDue,
        rto24SecurityDeposit,
        rto36SecurityDeposit,
        rto48SecurityDeposit,
        rto24TaxOnSecurityDeposit,
        rto36TaxOnSecurityDeposit,
        rto48TaxOnSecurityDeposit,
        rto24DueToday,
        rto36DueToday,
        rto48DueToday,
        rto24TotalOwnershipCost,
        rto36TotalOwnershipCost,
        rto48TotalOwnershipCost,
      };

    }

    downloadFilledRTOContract(){      
      return;
    }

    downloadFilledACHContract(){      
      return;
    }
        
    createPDFFormMap(){
      let totals = this.getTotals();
      let monthlyPayment;
      let monthlyTax;
      let monthlyTotal;
      let totalDeposit;
      let totalOwnership;
      let rtoTerm;
      let rtoPerc;
      switch(this.state["payment_term"].value){
        case "RTO_24":
          monthlyPayment=totals.rto24PreTax;
          monthlyTax=totals.rto24Tax;
          monthlyTotal=totals.rto24TotalDue;
          totalDeposit=totals.rto24SecurityDeposit;
          totalOwnership=totals.rto24TotalOwnershipCost;
          rtoTerm="24";
          rtoPerc="70";
          break;
        case "RTO_36":
          monthlyPayment=totals.rto36PreTax;
          monthlyTax=totals.rto36Tax;
          monthlyTotal=totals.rto36TotalDue;
          totalDeposit=totals.rto36SecurityDeposit;
          totalOwnership=totals.rto36TotalOwnershipCost;
          rtoTerm="36";
          rtoPerc="60";
          break;
        case "RTO_48":
          monthlyPayment=totals.rto48PreTax;
          monthlyTax=totals.rto48Tax;
          monthlyTotal=totals.rto48TotalDue;
          totalDeposit=totals.rto48SecurityDeposit;
          totalOwnership=totals.rto48TotalOwnershipCost;
          rtoTerm="48";
          rtoPerc="50";
          break;
        default:
          monthlyPayment=0;
          monthlyTax=0;
          monthlyTotal=0;
          totalDeposit=0;
          totalOwnership=0;
      }
      return {
        'name': `${this.state.first_name.value} ${this.state.last_name.value}`,
        'name2': `${this.state.first_name.value} ${this.state.last_name.value}`,
        'address': `${this.state.billing_street_address.value} ${this.state.billing_city.value}, ${this.state.billing_state.value.toUpperCase()} ${this.state.billing_zipcode.value}`,
        'phone': this.state.primary_phone.value,
        'used': this.state["build_type"].value === "ON-LOT-USED" ? "X":"",
        'new': this.state["build_type"].value === "ON-LOT-USED" ? "":"X",
        'cashPrice':currencyFormat(totals.cashPriceOfLeased),
        'monthlyPayment':currencyFormat(monthlyPayment),
        'monthlyTax':currencyFormat(monthlyTax),
        'monthlyTotal':currencyFormat(monthlyTotal),
        'totalDeposit':currencyFormat(totalDeposit),
        'totalOwnership':currencyFormat(totalOwnership),
        'description': `${this.state["inventory_number"].value}  ${this.state["model"].value} ${this.state["building_size"].value} ${this.state["exterior"].value}`.trim(),
        'email': this.state.email.value,
        'rtoTerm': rtoTerm,
        'rtoPerc': rtoPerc,
        'rtoPerc2': rtoPerc,
      };
    }

    async handleSubmission(){
      this.setState({ 
          loading:true,
      });

      let json = this.formatJson();
      console.log(json);

      // push to url
      try {
          let resp =null;
          let toastMessage="";
          if(this.state.orderId){
            resp= await HTTPService.editOrder(this.state.orderId,json,this.props.history);
            toastMessage="Order updated";
          }else{
            resp= await HTTPService.newOrder(json,this.props.history);
            console.log(resp)
            this.setState({ 
              orderId:resp.id,
            });
            toastMessage="Order added";
          }
          Toast.success(toastMessage);
      } catch (error) {
        console.log(error);
        Toast.error("Failed. Please try again later.");
      }
      
      this.setState({ 
          loading:false,
      });
      return;
    }
    
    async handleDocusign(){
      this.setState({ 
          loading:true,
      });

      try {
          if(this.state.orderId){
            let resp = await HTTPService.sendDocusign(this.state.orderId,{},this.props.history);
            let toastMessage="Docusign Sent";
            Toast.success(toastMessage);
            this.props.history.push(Navigation.paths.orders);
          }
      } catch (error) {
        console.log(error);
        Toast.error("Failed. Please try again later.");
      }
      
      this.setState({ 
          loading:false,
      });
      return;
    }

    render(){
        let totals = this.getTotals();

        return (
          <FlexRowCenterCenter>
            <FlexColumnStartStretch style={{
              maxWidth:1000,
              paddingBottom:750,
              // backgroundColor:"#d4d4d4",
            }}>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomDateTimeInput                
                    value={this.state["sale_date"].value}                
                    onChange={(value,isValid)=>this.handleChange("sale_date",value,isValid)}
                    title={"Sale Date"}
                    validateAlways={this.state.validateAlways}
                  />
                  <CustomTextInput                
                    value={this.state["salesman"].value}                
                    onChange={(value,isValid)=>this.handleChange("salesman",value,isValid)}
                    title={"Salesman"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
              </FlexRowBetweenStretch>
              <Typography variant="h1" style={{
                marginBottom:15,
              }}>
                Customer Info
              </Typography>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                <CustomTextInput          
                    value={this.state["first_name"].value}                
                    onChange={(value,isValid)=>this.handleChange("first_name",value,isValid)}
                    title={"First Name"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomTextInput                
                    value={this.state["last_name"].value}                
                    onChange={(value,isValid)=>this.handleChange("last_name",value,isValid)}
                    title={"Last Name"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />

              </FlexRowBetweenStretch>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomEmailInput                
                    value={this.state["email"].value}                
                    onChange={(value,isValid)=>this.handleChange("email",value,isValid)}
                    title={"Email"}
                    validateAlways={this.state.validateAlways}
                  />
                <CustomTelephoneInput          
                    value={this.state["primary_phone"].value}                
                    onChange={(value,isValid)=>this.handleChange("primary_phone",value,isValid)}
                    title={"Primary Phone"}
                    validateAlways={this.state.validateAlways}
                />
                  <CustomTelephoneInput                
                    value={this.state["secondary_phone"].value}                
                    onChange={(value,isValid)=>this.handleChange("secondary_phone",value,isValid)}
                    title={"Secondary Phone"}
                    validateAlways={this.state.validateAlways}
                />
              </FlexRowBetweenStretch>
              <Typography variant="h6" style={{
                marginBottom:15,
              }}>
                Billing Address
              </Typography>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                <CustomTextInput          
                    value={this.state["billing_street_address"].value}                
                    onChange={(value,isValid)=>this.handleChange("billing_street_address",value,isValid)}
                    title={"Street Address"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomTextInput                
                    value={this.state["billing_city"].value}                
                    onChange={(value,isValid)=>this.handleChange("billing_city",value,isValid)}
                    title={"City"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <StateSelectInput
                    value={this.state["billing_state"].value}                
                    onChange={(value,isValid)=>this.handleChange("billing_state",value,isValid)}
                    title={"State"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomZipcodeInput
                    value={this.state["billing_zipcode"].value}                
                    onChange={(value,isValid)=>this.handleChange("billing_zipcode",value,isValid)}
                    title={"Zipcode"}
                    validateAlways={this.state.validateAlways}
                  />
              </FlexRowBetweenStretch>
              
              <Typography variant="h6" style={{
                marginBottom:15,
              }}>
                Mailing Address (if different)
              </Typography>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                <CustomTextInput          
                    value={this.state["mailing_street_address"].value}                
                    onChange={(value,isValid)=>this.handleChange("mailing_street_address",value,isValid)}
                    title={"Street Address"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomTextInput                
                    value={this.state["mailing_city"].value}                
                    onChange={(value,isValid)=>this.handleChange("mailing_city",value,isValid)}
                    title={"City"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <StateSelectInput
                    value={this.state["mailing_state"].value}                
                    onChange={(value,isValid)=>this.handleChange("mailing_state",value,isValid)}
                    title={"State"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomZipcodeInput
                    value={this.state["mailing_zipcode"].value}                
                    onChange={(value,isValid)=>this.handleChange("mailing_zipcode",value,isValid)}
                    title={"Zipcode"}
                    validateAlways={this.state.validateAlways}
                  />
              </FlexRowBetweenStretch>
              <Typography variant="h1" style={{
                marginBottom:15,
              }}>
                Shed Info
              </Typography>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomSelect           
                    value={this.state["build_type"].value}                
                    onChange={(value,isValid)=>this.handleChange("build_type",value,isValid)}
                    title={"Type"}
                    validateAlways={this.state.validateAlways}
                    options={[
                      {
                        name:"On Lot Used",
                        value:"ON-LOT-USED",
                      },
                      {
                        name:"On Lot New",
                        value:"ON-LOT-NEW",
                      },
                      {
                        name:"New Build",
                        value:"NEW-BUILD",
                      },
                    ]}
                  />
                  <CustomTextInput                
                    value={this.state["inventory_number"].value}                
                    onChange={(value,isValid)=>this.handleChange("inventory_number",value,isValid)}
                    title={"Inventory Number"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <Button
                    variant="contained"
                    color="secondary" 
                    fullWidth={true}
                    href="https://shedview.605sheds.com/" 
                    target="_blank"
                    style={{
                      color:"white",
                    }}
                  >
                    Open 3D Modeler
                  </Button>
              </FlexRowBetweenStretch>
              <FlexRowStartStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                <div style={{
                  width:210,
                }}>
                  <CustomCheckboxInput
                    value={this.state["has_3D"].value}                
                    onChange={(value,isValid)=>this.handleChange("has_3D",value,isValid)}
                    title={"Has 3D Model"}
                    validateAlways={this.state.validateAlways}
                  />
                </div>
                  <CustomCheckboxInput
                    value={this.state["has_hand_drawing"].value}                
                    onChange={(value,isValid)=>this.handleChange("has_hand_drawing",value,isValid)}
                    title={"Has Hand Drawing"}
                    validateAlways={this.state.validateAlways}
                  />
              </FlexRowStartStretch>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomSelect           
                    value={this.state["model"].value}                
                    onChange={(value,isValid)=>this.handleChange("model",value,isValid)}
                    title={"Model"}
                    validateAlways={this.state.validateAlways}
                    options={[
                      {
                        value:"DELUXE_LOFTED_BARN",
                        name:"Deluxe Lofted Barn",
                      },
                      {
                        value:"GARAGE",
                        name:"Garage",
                      },
                      {
                        value:"LOFTED_GARAGE",
                        name:"Lofted Garage",
                      },
                      {
                        value:"UTILITY",
                        name:"Utility",
                      },
                      {
                        value:"LOFTED_BARN",
                        name:"Lofted Barn",
                      },
                      {
                        value:"SIDE_UTILITY",
                        name:"Side Utility",
                      },
                      {
                        value:"SIDE_LOFTED_BARN",
                        name:"Side Lofted Barn",
                      },
                      {
                        value:"CABIN",
                        name:"Cabin",
                      },
                      {
                        value:"LOFTED_BARN_CABIN",
                        name:"Lofted Barn Cabin",
                      },
                      {
                        value:"ONE_RUN_DOG_KENNEL",
                        name:"One Run Dog Kennel",
                      },
                      {
                        value:"TWO_RUN_DOG_KENNEL",
                        name:"Two Run Dog Kennel",
                      },
                    ]}
                  />
                  <CustomTextInput                
                    value={this.state["building_size"].value}                
                    onChange={(value,isValid)=>this.handleChange("building_size",value,isValid)}
                    title={"Building Size"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomSelect           
                    value={this.state["exterior"].value}                
                    onChange={(value,isValid)=>this.handleChange("exterior",value,isValid)}
                    title={"Exterior"}
                    validateAlways={this.state.validateAlways}
                    options={[
                      {
                        value:"URETHANE",
                        name:"Urethane",
                      },
                      {
                          value:"METAL",
                          name:"Metal",
                      },
                      {
                          value:"PAINT",
                          name:"Paint",
                      },
                    ]}
                  />
              </FlexRowBetweenStretch>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomTextInput                
                    value={this.state["side_wall_color"].value}                
                    onChange={(value,isValid)=>this.handleChange("side_wall_color",value,isValid)}
                    title={"Side Wall Color"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomTextInput                
                    value={this.state["roof_color"].value}                
                    onChange={(value,isValid)=>this.handleChange("roof_color",value,isValid)}
                    title={"Roof Color"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
                  <CustomTextInput                
                    value={this.state["trim_color"].value}                
                    onChange={(value,isValid)=>this.handleChange("trim_color",value,isValid)}
                    title={"Trim Color"}
                    validateAlways={this.state.validateAlways}
                    maxLength={244}
                  />
              </FlexRowBetweenStretch>

              <div style={{
                marginBottom:this.rowSpacing,
              }}>
                <OrderAdditionsComponent
                  value={this.state["order_additions"].value}
                  onChange={(value,isValid)=>this.handleChange("order_additions",value,isValid)}
                />
              </div>
              
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomTextArea                
                    value={this.state["build_notes"].value}                
                    // onChange={(value,isValid)=>this.handleChange("build_notes",value,isValid)}
                    onChange={(value,isValid)=>{this.handleChange("build_notes",value,isValid)}}
                    title={"Build Notes"}
                    validateAlways={this.state.validateAlways}
                    // validateAlways={false}
                    rows={5}                    
                  />
                  <CustomTextArea                
                    value={this.state["other_notes"].value}                
                    onChange={(value,isValid)=>this.handleChange("other_notes",value,isValid)}
                    title={"Other Notes"}
                    validateAlways={this.state.validateAlways}
                    rows={5}                       
                  />
              </FlexRowBetweenStretch>
              <Typography variant="h1" style={{
                marginBottom:15,
              }}>
                Payment
              </Typography>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                  <CustomSelect           
                    value={this.state["payment_method"].value}                
                    onChange={(value,isValid)=>this.handleChange("payment_method",value,isValid)}
                    title={"Method"}
                    validateAlways={this.state.validateAlways}
                    options={[
                      {
                        value:"CASH",
                        name:"Cash",
                      },
                      {
                          value:"CARD",
                          name:"Card",
                      },
                      {
                          value:"CHECK_ACH",
                          name:"Check/ACH",
                      },
                      {
                          value:"OTHER",
                          name:"Other",
                      },
                    ]}
                  />
                  <CustomSelect           
                    value={this.state["payment_term"].value}                
                    onChange={(value,isValid)=>this.handleChange("payment_term",value,isValid)}
                    title={"Term"}
                    validateAlways={this.state.validateAlways}
                    options={[
                      {
                        value:"UPFRONT",
                        name:"Upfront",
                      },
                      {
                          value:"RTO_24",
                          name:"RTO 24",
                      },
                      {
                          value:"RTO_36",
                          name:"RTO 36",
                      },
                      {
                          value:"RTO_48",
                          name:"RTO 48",
                      },
                    ]}
                  />
                  <CustomNumberInput                
                    value={this.state["building_price"].value}                
                    onChange={(value,isValid)=>this.handleChange("building_price",value,isValid)}
                    title={"Building Price"}
                    validateAlways={this.state.validateAlways}
                    icon={"$"}
                    step={0.01}
                    minValue={0}
                  />
              </FlexRowBetweenStretch>
              <FlexRowBetweenStretch 
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                <CustomNumberInput                
                    value={this.state["additional_deposit"].value}                
                    onChange={(value,isValid)=>this.handleChange("additional_deposit",value,isValid)}
                    title={"Additional Deposit"}
                    validateAlways={this.state.validateAlways}
                    icon={"$"}
                    step={0.01}
                    minValue={0}
                  />
                  <CustomNumberInput                
                    value={this.state["tax_rate"].value}                
                    onChange={(value,isValid)=>this.handleChange("tax_rate",value,isValid)}
                    title={"Sales Tax Rate"}
                    validateAlways={this.state.validateAlways}
                    //placeholder={null}
                    //icon={"%"}
                    step={0.01}
                    minValue={0}
                  />
              </FlexRowBetweenStretch>
              <FlexRowBetweenStretch
              spacing={this.spacing}
              style={{
                marginBottom:this.rowSpacing,
              }}>
                <TotalsComponent totals={totals} type={this.state["payment_term"].value}/>
                <FlexColumnBetweenStretch 
                spacing={this.spacing}
                style={{
                    // alignSelf:"flex-start",
                    width:"100%",
                    flexShrink:1,
                  }}>
                  <FlexRowStartStretch spacing={this.spacing}>
                    <Button
                      variant="contained"
                      color="secondary" 
                      fullWidth={true}
                      startIcon={<GetAppIcon />}                      
                      onClick={()=>{
                        this.downloadFilledRTOContract();
                        this.downloadFilledACHContract();
                      }}
                      style={{
                        alignSelf:"flex-start",
                        flexShrink:1,
                        fontSize:12,
                      }}
                    >
                      RTO Contract
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary" 
                      fullWidth={true}
                      startIcon={<GetAppIcon />}     
                      onClick={()=>{
                        this.downloadFilledACHContract();
                      }}
                      style={{
                        alignSelf:"flex-start",
                        flexShrink:1,
                        fontSize:12,
                      }}
                    >
                      ACH/CC Form
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary" 
                      fullWidth={true}
                      startIcon={<GetAppIcon />}
                      onClick={()=>{}}
                      style={{
                        alignSelf:"flex-start",
                        flexShrink:1,
                        fontSize:12,
                      }}
                    >
                      Order Form
                    </Button>
                  </FlexRowStartStretch>
                  <Payment 
                  history={this.props.history}
                  orderId={this.state.orderId}
                  name={`${this.state["first_name"].value} ${this.state["last_name"].value}`}
                  email={this.state["email"].value}
                  phone={this.state["primary_phone"].value}
                  onSuccess={(amount,intentId)=>{this.setState({
                    amount_received:{
                      value:amount,
                      isValid:true,
                    },
                    stripe_id:{
                      value:intentId,
                      isValid:true,
                    },
                  });
                  if(this.state.orderId){
                  this.handleSubmission()
                  }
                    
                  }}
                  amountCharged={this.state.amount_received.value}
                  />
                </FlexColumnBetweenStretch>
              </FlexRowBetweenStretch>              
              <Button 
                style={{
                  marginTop:50,
                }}
                variant="contained" 
                color="primary" 
                fullWidth={true}
                onClick={this.state.loading ? ()=>{}:this.handleSubmission}
                disabled={!this.state.allFieldsValid && !this.props.validateOnSubmit}
                startIcon={this.state.loading ? null:<SaveIcon/>}                
                >
                    {this.state.loading ? 
                    (
                        <CircularProgress color="secondary" size={25}/>
                    )
                    : this.state.orderId ? "Update Order":"Save Order"
                    }
              </Button>   
              <Button 
                style={{
                  marginTop:50,
                }}
                variant="contained" 
                color="primary" 
                fullWidth={true}
                onClick={this.state.loading ? ()=>{}:this.handleDocusign}
                disabled={(!this.state.allFieldsValid && !this.props.validateOnSubmit) || !this.state.orderId}
                startIcon={this.state.loading ? null:<SendIcon/>}
                >
                    {this.state.loading ? 
                    (
                        <CircularProgress color="secondary" size={25}/>
                    )
                    :  "Submit & Send Docusign"
                    }
              </Button>  

{/*         */}
{this.props.showDelete && (
              <div
                style={{
                  marginTop: 100,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: Theme.errorColor,
                  }}
                  color={Theme.errorColor}
                  fullWidth={true}
                  onClick={() => this.setState({ showDeleteDialog: true })}
                  endIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </div>
            )}

            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              open={this.state.showDeleteDialog}
              onClose={() => this.setState({ showDeleteDialog: false })}
            >
              <DialogTitle>{this.props.deleteDialogTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.props.deleteDialogBody}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ showDeleteDialog: false })}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
                <div
                  style={{
                    color: Theme.errorColor
                  }}
                >
                  <Button onClick={this.handleDeletion} color="inherit">
                    Delete
                  </Button>
                </div>
              </DialogActions>
            </Dialog>


{/*         */}


            </FlexColumnStartStretch>
          </FlexRowCenterCenter>
        );
    }
}


export {OrderForm};


class OrderAdditionsComponent extends React.Component{
  spacing=10;
  rowSpacing=20;

  static propTypes={
    value:PropTypes.array,
    onChange:PropTypes.func,
  };
  
  constructor(props) {
      super(props);
      this.state={

      };
      
      this.addRow=this.addRow.bind(this);
      this.removeRow=this.removeRow.bind(this);
      this.validateData=this.validateData.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  addRow(newRow){
    return new Promise((resolve, reject) => {
      let isValid = this.validateData(newRow);
      if(isValid){
        let newArray = [...this.props.value,newRow];
        this.props.onChange(newArray,true);
        resolve();
      }else{
        reject();
      }
    });
  }

  removeRow(oldData){
    return new Promise((resolve, reject) => {
      let newArray = [...this.props.value].filter(item=>{
        return !(item.description === oldData.description && item.quantity === oldData.quantity && item.unit_price === oldData.unit_price)
      });
      this.props.onChange(newArray,true);
      resolve();
    });
  }

  validateData(data){
    let isValid = true;
    if(!data.description || data.description===""){
      isValid=false;
    } 
    if(!data.quantity || isNaN(data.quantity)  ){
      isValid=false;
    } 
    if(!data.unit_price || isNaN(data.unit_price)  ){
      isValid=false;
    } 
    return isValid;
  }

  render(){
    let total = 0;
    let data = this.props.value.map((item)=>{
      let currTotal = item.quantity*item.unit_price;
      total=total+currTotal;
      return {...item,total:currTotal};
    });
    return(
      <FlexColumnStartStretch>
          <MaterialTable
            columns={[
              { title: "Description", field: "description"},
              { title: "Quantity", field: "quantity", type:"numeric"},
              { title: "Unit Price", field: "unit_price", type:"currency", headerStyle: {textAlign:"right",}},
              { title: "Total", field: "total", type:"currency", headerStyle: {textAlign:"right",},
                editComponent: props => (
                  null
                )
              },
            ]}
            data={data}
            title={"Additional Options"}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => true, 
              onRowAdd: newData => this.addRow(newData),
              onRowDelete: oldData => this.removeRow(oldData),
            }}
            options={{
              actionsColumnIndex:-1,
              exportButton: false,
              search:false,
              selection:false,
              filtering: false,
              sorting: false,
              exportAllData:false,
              paging:false,
              draggable:false,
              toolbar:true,
              columnsButton:false,
              padding:"dense",
            }}
          />      
          <Card style={{
            alignSelf:"flex-end",
            padding:"10px 20px",
            marginTop:20,
          }}>
            <FlexRowEndStretch spacing={15} style={{
            }}>
              <h6 style={{
                margin:0,
              }}>
                Total
              </h6>
              <h6 style={{
                margin:0,
              }}>
                {currencyFormat(total)}
              </h6>
            </FlexRowEndStretch>
          </Card>

      </FlexColumnStartStretch>
    );
  }

}


