import React from 'react';
import HTTPService from "services/http.service";
import {deepGet,sortObjectListInPlace} from "services/helpers.service";
import Toast from "services/toast.service";
import {PrimaryButton, 
  CircleButton, 
  Loader, 
  ConfirmDialog, 
  InputDialog,
  CustomTextArea,
  EmailInput,
  PasswordInput,
  InputErrorMessage,
  FlexBox,
  FlexRowBetweenStretch,
  FlexColumnBetweenStretch,
  FlexRowBetweenCenter,
  FlexColumnBetweenCenter,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  FlexRowStartCenter,
  FlexColumnStartCenter,
  FlexRowEndStretch,
  FlexColumnEndStretch,
  FlexRowEndCenter,
  FlexColumnEndCenter,
  FlexRowAroundStretch,
  FlexColumnAroundStretch,
  FlexRowAroundCenter,
  FlexColumnAroundCenter,
  Card,
} from "components";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";

class UserDetail extends React.Component{

  constructor(props) {
    super(props);
    this.state={
      loading:true,
    };

  }


  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    return (
      this.state.loading ? <Loader/>:
        <FlexColumnStartStretch>
          <h1>
            User Detail
          </h1>
        </FlexColumnStartStretch>
    );
  }
}


export default withRouter(UserDetail);
