import React from 'react';
import PropTypes from 'prop-types';
import { 
  withRouter,
} from "react-router-dom";
import {
  FlexRowCenterCenter,
  FlexColumnStartStretch,
  FlexRowBetweenCenter,
  FlexRowBetweenStretch,
  FlexRowStartStretch,
  FlexRowEndStretch,
  FlexColumnBetweenStretch,
} from "./flex.component.js";
import { 
  Card,
  Divider,
} from '@material-ui/core';
import {currencyFormat, percentFormat} from "services/helpers.service";

class TotalsComponent extends React.Component{
  static propTypes={
    totals:PropTypes.object,
    type:PropTypes.string,
  };
  
  constructor(props) {
      super(props);
      this.state={

      };
      
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){

    let isUpfront = this.props.type==="UPFRONT";
    let isRTO24 = this.props.type==="RTO_24";
    let isRTO36 = this.props.type==="RTO_36";
    let isRTO48 = this.props.type==="RTO_48";

    return (
      <Card style={{
        padding:"20px 20px",
        minWidth:355,
      }}>
      <FlexColumnStartStretch  spacing={5}>
          <FlexRowStartStretch spacing={15} style={{
          }}>
            <h6>
              Building Price
            </h6>
            <h6 style={{
                marginLeft:"auto",
              }}>
              {currencyFormat(this.props.totals.buildingPrice)}
            </h6>
          </FlexRowStartStretch>
          <FlexRowStartStretch spacing={15} style={{
          }}>
            <h6>
              Options Total
            </h6>
            <h6 style={{
                marginLeft:"auto",
              }}>
              {currencyFormat(this.props.totals.additionsTotal)}
            </h6>
          </FlexRowStartStretch>
          <FlexRowStartStretch spacing={15} style={{
          }}>
            <h6>
              Pre-tax Total
            </h6>
            <h6 style={{
                marginLeft:"auto",
              }}>
              {currencyFormat(this.props.totals.preTaxTotal)}
            </h6>
          </FlexRowStartStretch>
          {(isRTO24||isRTO36||isRTO48) &&
                <FlexRowStartStretch spacing={15} style={{
                }}>
                  <h6>
                    Additional Deposit
                  </h6>
                  <h6 style={{
                      marginLeft:"auto",
                    }}>
                    {currencyFormat(this.props.totals.additionalDeposit)}
                  </h6>
                </FlexRowStartStretch>
          }
          {(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
                }}>
                  <h6>
                    Cash Price of Leased
                  </h6>
                  <h6 style={{
                      marginLeft:"auto",
                    }}>
                    {currencyFormat(this.props.totals.cashPriceOfLeased)}
                  </h6>
                </FlexRowStartStretch>                    
          }
          {isRTO24 &&
              <FlexRowStartStretch spacing={15} style={{
                }}>
                  <h6>
                    RTO 24 Monthly Payment
                  </h6>
                  <h6 style={{
                      marginLeft:"auto",
                    }}>
                    {currencyFormat(this.props.totals.rto24PreTax)}
                  </h6>
                </FlexRowStartStretch>                    
          }
          {isRTO36 &&
              <FlexRowStartStretch spacing={15} style={{
                }}>
                  <h6>
                    RTO 36 Monthly Payment
                  </h6>
                  <h6 style={{
                      marginLeft:"auto",
                    }}>
                    {currencyFormat(this.props.totals.rto36PreTax)}
                  </h6>
                </FlexRowStartStretch>                    
          }
          {isRTO48 &&
              <FlexRowStartStretch spacing={15} style={{
                }}>
                  <h6>
                    RTO 48 Monthly Payment
                  </h6>
                  <h6 style={{
                      marginLeft:"auto",
                    }}>
                    {currencyFormat(this.props.totals.rto48PreTax)}
                  </h6>
                </FlexRowStartStretch>                    
          }

          <FlexRowStartStretch spacing={15} style={{
          }}>
            <h6>
              Sales Tax Rate
            </h6>
            <h6 style={{
                marginLeft:"auto",
              }}>
              {percentFormat(this.props.totals.salesTaxRate)}
            </h6>
          </FlexRowStartStretch>
          {(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
                }}>
                  <h6>
                    Monthly Tax
                  </h6>
                  <h6 style={{
                      marginLeft:"auto",
                    }}>
                    {isRTO24 && currencyFormat(this.props.totals.rto24Tax)}
                    {isRTO36 && currencyFormat(this.props.totals.rto36Tax)}
                    {isRTO48 && currencyFormat(this.props.totals.rto48Tax)}
                  </h6>
                </FlexRowStartStretch>                    
          }
          {!(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
              }}>
                <h6 >
                  Tax Amount
                </h6>
                <h6 style={{
                    marginLeft:"auto",
                  }}>
                  {currencyFormat(this.props.totals.tax)}
                </h6>
              </FlexRowStartStretch>
          }
          {(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
                fontWeight:"bold",
              }}>
                <h6 style={{
                  fontWeight:"bold",
                }}>
                  Total Monthly Payment
                </h6>
                <h6 style={{
                    marginLeft:"auto",
                    fontWeight:"bold",
                  }}>
                    {isRTO24 && currencyFormat(this.props.totals.rto24TotalDue)}
                    {isRTO36 && currencyFormat(this.props.totals.rto36TotalDue)}
                    {isRTO48 && currencyFormat(this.props.totals.rto48TotalDue)}
                </h6>
              </FlexRowStartStretch>
          }
          {(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
              }}>
                <h6>
                  Ownership Cost
                </h6>
                <h6 style={{
                    marginLeft:"auto",
                  }}>
                    {isRTO24 && currencyFormat(this.props.totals.rto24TotalOwnershipCost)}
                    {isRTO36 && currencyFormat(this.props.totals.rto36TotalOwnershipCost)}
                    {isRTO48 && currencyFormat(this.props.totals.rto48TotalOwnershipCost)}
                </h6>
              </FlexRowStartStretch>
          }
          {(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
              }}>
                <h6>
                  Total Security Deposit
                </h6>
                <h6 style={{
                    marginLeft:"auto",
                  }}>
                    {isRTO24 && currencyFormat(this.props.totals.rto24SecurityDeposit)}
                    {isRTO36 && currencyFormat(this.props.totals.rto36SecurityDeposit)}
                    {isRTO48 && currencyFormat(this.props.totals.rto48SecurityDeposit)}
                </h6>
              </FlexRowStartStretch>
          }
          {(isRTO24||isRTO36||isRTO48) &&
              <FlexRowStartStretch spacing={15} style={{
              }}>
                <h6>
                  Tax on Security Deposit
                </h6>
                <h6 style={{
                    marginLeft:"auto",
                  }}>
                    {isRTO24 && currencyFormat(this.props.totals.rto24TaxOnSecurityDeposit)}
                    {isRTO36 && currencyFormat(this.props.totals.rto36TaxOnSecurityDeposit)}
                    {isRTO48 && currencyFormat(this.props.totals.rto48TaxOnSecurityDeposit)}
                </h6>
              </FlexRowStartStretch>
          }

          
          <Divider/>
          {!(isRTO24||isRTO36||isRTO48) &&
            <FlexRowStartStretch spacing={15} style={{
              fontWeight:"bold",
            }}>
              <h6 style={{
                  fontWeight:"bold",
                }}>
                Total Due
              </h6>
              <h6 style={{
                  marginLeft:"auto",
                  fontWeight:"bold",
                }}>
                {currencyFormat(this.props.totals.totalDue)}
              </h6>
            </FlexRowStartStretch>
          }
          {(isRTO24||isRTO36||isRTO48) &&
            <FlexRowStartStretch spacing={15} style={{
              fontWeight:"bold",
            }}>
              <h6 style={{
                  fontWeight:"bold",
                }}>
                Total Due Today
              </h6>
              <h6 style={{
                  marginLeft:"auto",
                  fontWeight:"bold",
                }}>
                    {isRTO24 && currencyFormat(this.props.totals.rto24DueToday)}
                    {isRTO36 && currencyFormat(this.props.totals.rto36DueToday)}
                    {isRTO48 && currencyFormat(this.props.totals.rto48DueToday)}
              </h6>
            </FlexRowStartStretch>
          }
      </FlexColumnStartStretch>
    </Card>

    );
  }
}

export {TotalsComponent};