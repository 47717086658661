import React from 'react';
import PropTypes from 'prop-types';
import {
    CustomInput, 
    EmailInput, 
    PasswordInput, 
    InputErrorMessage, 
    CustomTextArea, 
    CustomSelect,
    StateSelectInput,
    CustomNumberInput,
    CustomPasswordInput,
    CustomTelephoneInput,
    CustomTextInput,
    CustomUrlInput,
    CustomDateInput,
  } from "./inputs.component.js";
import {
    FlexRowCenterCenter,
    FlexColumnStartStretch,
} from "./flex.component.js";
import {
PrimaryButton,
} from "./buttons.component.js";
import {Toast} from "services/toast.service";
import { 
    Button, 
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import Theme from 'theme/main.theme.js';
// import Navigation from "services/navigation.service.js";
import {deepGet} from "services/helpers.service";

class CustomForm extends React.Component{
    // types: text, select, text-area, number, date, url, password, email
    // specialTypes: select-state, zipcode

    static propTypes={
        style:PropTypes.object,
        children:PropTypes.node,
        formSchema:PropTypes.array,
        initialData:PropTypes.object,
        validateAlways:PropTypes.bool,
        validateOnTouched:PropTypes.bool,
        validateOnBlur:PropTypes.bool,
        validateOnSubmit:PropTypes.bool,
        showSuccessfulValidation:PropTypes.bool,
        buttonLabel:PropTypes.string,
        submissionFunction:PropTypes.func,
        submissionErrorMessage:PropTypes.string,
        submissionSuccessMessage:PropTypes.string,
        submissionSuccessRedirectFunction:PropTypes.func,
        showDelete:PropTypes.bool,
        deletionFunction:PropTypes.func,
        deletionSuccessRedirectFunction:PropTypes.func,
        deleteDialogTitle:PropTypes.string,
        deleteDialogBody:PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state={
            fieldNameList:[],
            showValidation:false,
            allFieldsValid:false,
            firstValidationComplete:false,
            loading:false,
            showDeleteDialog:false,
        };

        // Init input fields
        let initInputFields = (items) => {
            items.forEach((item)=>{

                if(item.remove){
                    return;
                }

                if(item.type==="row"){
                    initInputFields(item.items);
                }else{
                    let pathToVar=null;
                    if(item.path){
                        pathToVar=item.path;
                    }else{
                        pathToVar=item.fieldName;
                    }       

                    if(item.type==="checkbox"){
                        this.state[item.fieldName]={
                            value:props.initialData ? deepGet(props.initialData,pathToVar,false) : (item.value==null ? false:item.value),
                            isValid:false,
                        };
                    }else{
                        this.state[item.fieldName]={
                            value:props.initialData ? deepGet(props.initialData,pathToVar,"") : (item.value==null ? "":item.value),
                            isValid:false,
                        };
                    }

                    // Force select items to string
                    if(item.type==="select"){
                        this.state[item.fieldName].value=String(this.state[item.fieldName].value)
                    }

                    this.state.fieldNameList.push(item.fieldName);
                }
            });
        }
        initInputFields(props.formSchema);

        
        this.handleChange = this.handleChange.bind(this);
        this.renderInputField = this.renderInputField.bind(this);
        this.handleSubmission = this.handleSubmission.bind(this);
        this.handleDeletion = this.handleDeletion.bind(this);
        this.checkAllFieldsValid = this.checkAllFieldsValid.bind(this);
        
    }

    componentDidMount(){
        if(this.props.validateAlways){           
            this.checkAllFieldsValid();
        }
    }

    handleChange(key,value,isValid) {
        this.setState({ 
            [key]:{
                ...this.state[key],
                value:value,
                isValid:isValid,  
            }
        },this.checkAllFieldsValid);
        return;
    }


    checkAllFieldsValid(){
        let allValid=true;
        for (let index = 0; index < this.state.fieldNameList.length; index++) {
            const fieldName = this.state.fieldNameList[index];
            if(!this.state[fieldName].isValid){
                allValid=false;
            }
        }
        this.setState({        
            allFieldsValid: allValid,
        });
        return;
    }

    async handleSubmission() {
        if(this.props.validateOnSubmit && !this.state.allFieldsValid){
            this.setState({ 
                showValidation:true,
            });
            return;
        }

        this.setState({ 
            loading:true,
        });

        // create json
        let json = this.state.fieldNameList.reduce((prev,curr)=>({
            ...prev,
            [curr]:this.state[curr].value,
        }),{});

        // push to url
        try {
            let resp = await this.props.submissionFunction(json);
            console.log(resp)
            // Toast w/ this.props.submissionSuccessMessage
            if(this.props.submissionSuccessMessage){
                Toast.success(this.props.submissionSuccessMessage);
            }
            // Redirect
            if(this.props.submissionSuccessRedirectFunction){
                this.props.submissionSuccessRedirectFunction();
            }
        } catch (error) {
            // Toast w/ this.props.submissionErrorMessage
            if(this.props.submissionErrorMessage){
                Toast.error(this.props.submissionErrorMessage);
            }
        }
        
        this.setState({ 
            loading:false,
        });
        return;
    }

    async handleDeletion() {
        this.setState({ 
            loading:true,
            showDeleteDialog:false,
        });

        // push to url
        try {
            let resp = await this.props.deletionFunction();
            Toast.warning("Deleted successfully");

            // Redirect
            if(this.props.deletionSuccessRedirectFunction){
                this.props.deletionSuccessRedirectFunction();
            }
        } catch (error) {
            Toast.error("Unable to Delete. Please try again later.");
        }
        
        this.setState({ 
            loading:false,
        });
        return;
    }

    renderInputField(item){
        if (item.type==="row"){
            return (
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"stretch",
                    ...item.style,
                    }}>
                    {item.items.map((inputItem)=>(
                        this.renderInputField(inputItem)
                    ))}
                </div>
            );
        }else if(item.type==="header"){
            return(
                <div style={{
                    marginBottom:20,
                }}>
                    <Typography variant="h5">
                        {item.value}
                    </Typography>
                </div>
            );
        }
        return (
            item.remove ? null:<CustomInput
                key={item.fieldName}
                style={{
                    marginBottom:item.hidden?0:12,
                    paddingLeft:12,
                    paddingRight:12,
                    flexGrow:1,
                    ...item.style,
                }}
                type={item.type}
                title={item.title}
                hidden={item.hidden}
                value={this.state[item.fieldName].value}
                onChange={(value,isValid)=>this.handleChange(item.fieldName,value,isValid)}
                placeholder={item.placeholder}
                icon={item.icon}
                error={item.error}
                success={item.success}
                disabled={item.disabled}
                validateAlways={this.props.validateAlways || this.state.showValidation}
                validateOnTouched={this.props.validateOnTouched}
                validateOnBlur={this.props.validateOnBlur}
                showSuccessfulValidation={this.props.showSuccessfulValidation}
                isRequired={item.isRequired}
                maxLength={item.maxLength}
                showMaxLength={item.showMaxLength}
                minLength={item.minLength}
                minValue={item.minValue}
                maxValue={item.maxValue}
                minDate={item.minDate}
                maxDate={item.maxDate}
                step={item.step}
                pattern={item.specialType==='zipcode' ? /(^\d{5}$)|(^\d{5}-\d{4}$)/:item.pattern}
                patternErrorMessage={item.specialType==='zipcode' ? "Must be a valid zipcode":item.patternErrorMessage}
                modifyInputOnChange={item.modifyInputOnChange}
                customValidationFunction={item.customValidationFunction}
                options={item.specialType==='state-select' ? STATEOPTIONSLIST:item.options}
                rows={item.rows}
                allowTextAreaResize={item.allowTextAreaResize}
            />
        );
    }

    render(){
        // alert(this.state.allFieldsValid );
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              ...this.props.style
            }}
          >
            {this.props.formSchema.map(item => this.renderInputField(item))}

            <div
              style={{
                marginTop: 25,
                paddingLeft: 12,
                paddingRight: 12
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={this.state.loading ? () => {} : this.handleSubmission}
                disabled={
                  !this.state.allFieldsValid && !this.props.validateOnSubmit
                }
                endIcon={this.state.loading ? null : <SendIcon />}
              >
                {this.state.loading ? (
                  <CircularProgress color="secondary" size={25} />
                ) : (
                  this.props.buttonLabel
                )}
              </Button>
            </div>
            {this.props.showDelete && (
              <div
                style={{
                  marginTop: 80,
                  paddingLeft: 12,
                  paddingRight: 12
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: Theme.errorColor,
                  }}
                  color={Theme.errorColor}
                  fullWidth={true}
                  onClick={() => this.setState({ showDeleteDialog: true })}
                  endIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </div>
            )}

            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              open={this.state.showDeleteDialog}
              onClose={() => this.setState({ showDeleteDialog: false })}
            >
              <DialogTitle>{this.props.deleteDialogTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.props.deleteDialogBody}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ showDeleteDialog: false })}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </Button>
                <div
                  style={{
                    color: Theme.errorColor
                  }}
                >
                  <Button onClick={this.handleDeletion} color="inherit">
                    Delete
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}


class CRUDForm extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    edit: PropTypes.bool,
    editObjectId: PropTypes.string,
    editGetFunction: PropTypes.func,
    postFunction: PropTypes.func,
    putFunction: PropTypes.func,
    showDelete: PropTypes.bool,
    deletionFunction: PropTypes.func,
    submissionSuccessRedirectFunction: PropTypes.func,
    deletionSuccessRedirectFunction: PropTypes.func,
    deleteDialogTitle: PropTypes.string,
    deleteDialogBody: PropTypes.string,
    formSchema: PropTypes.array,
    changePassword: PropTypes.bool,
    validateOnTouched: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      showValidation: false,
      loading: false,
      initialLoaded: false,
      editObject: null
    };

    this.pullData = this.pullData.bind(this);
    this.addObject = this.addObject.bind(this);
    this.updateObject = this.updateObject.bind(this);
    this.deleteObject = this.deleteObject.bind(this);
  }

  componentDidMount() {
    if (this.props.edit) {
      this.pullData();
    }
  }

  componentWillUnmount() {}

  async pullData() {
    this.setState({ loading: true });

    let editObject = await this.props.editGetFunction(
      this.props.editObjectId,
      this.props.history
    );
    console.log(editObject);

    this.setState({
      ...editObject,
      editObject: editObject,
      loading: false,
      initialLoaded: true
    });
  }

  async addObject(json) {
    return this.props.postFunction(json, this.props.history);
  }

  async updateObject(json) {
    console.log(this.props.editObjectId);

    if (this.props.changePassword) {
      return this.props.putFunction(json["new_password"]);
    } else {
      return this.props.putFunction(
        this.props.editObjectId,
        json,
        this.props.history
      );
    }
  }

  async deleteObject() {
    return this.props.deletionFunction(
      this.props.editObjectId,
      this.props.history
    );
  }

  render() {
    return (
      <FlexRowCenterCenter>
        {this.state.loading && <CircularProgress color="secondary" size={50} />}
        {(!this.props.edit || this.state.initialLoaded) && !this.state.loading && (
          <FlexColumnStartStretch
            style={{
              flexGrow: 1,
              padding: "0px 80px",
              maxWidth: 750
            }}
          >
            <CustomForm
              validateOnBlur={true}
              validateAlways={this.props.edit && !this.props.changePassword}
              initialData={this.state.editObject}
              showDelete={this.props.edit && this.props.showDelete}
              deletionFunction={this.deleteObject}
              deletionSuccessRedirectFunction={
                this.props.deletionSuccessRedirectFunction
              }
              deleteDialogTitle={this.props.deleteDialogTitle}
              deleteDialogBody={this.props.deleteDialogBody}
              buttonLabel={this.props.edit ? "Update" : "Submit"}
              submissionFunction={
                this.props.edit ? this.updateObject : this.addObject
              }
              submissionSuccessRedirectFunction={
                this.props.submissionSuccessRedirectFunction
              }
              submissionSuccessMessage={
                this.props.edit ? "Updated successfully" : "Added successfully"
              }
              submissionErrorMessage={
                this.props.edit
                  ? "Error updating. Please try again."
                  : "Error adding. Please try again."
              }
              formSchema={this.props.formSchema}
            />
          </FlexColumnStartStretch>
        )}
      </FlexRowCenterCenter>
    );
  }
}


export {CustomForm,CRUDForm};


// EXAMPLE Usage
{/* <CustomForm 
// validateOnBlur={true}
// validateOnTouched={true}
validateAlways={true}
// validateOnSubmit={true}
showSuccessfulValidation={true}
buttonLabel={"Update!!!!!!"}
submissionFunction={(json)=>console.log(json)}
submissionSuccessRedirectFunction={()=>{}}
submissionSuccessMessage={"Successful Submission!"}
submissionErrorMessage={"Error Submitting."}
formSchema={[
  {
    type:"tel",
    fieldName:"phone",
    title:"MY PHONE DAMN",
    value:"789658",
    placeholder:"applws",
    isRequired:true,
  },
  {
    type:"date",
    fieldName:"s",
    title:"MY PHONE DAMN",
    value:"2020-08-09",
  },
  {
    type:"row",
    items:[
      {
        type:"text",
        fieldName:"first1",
        title:"First",
        placeholder:"First",
        isRequired:true,
      },
      
      {
        type:"row",
        items:[
          {
            type:"text",
            fieldName:"first",
            title:"First",
            placeholder:"First",
            isRequired:true,
          },
          {
            type:"text",
            fieldName:"middle",
            title:"Middle",
            placeholder:"Middle",
            isRequired:true,
          },
          {
            type:"text",
            fieldName:"last",
            title:"Last",
            placeholder:"Last",
            isRequired:true,
            // maxLength:10,
          },
          {
            type:"select",
            specialType:'state-select',
            fieldName:"usstate",
            title:"State",
            isRequired:true,
          },
        ]
      },
    ]
  },
  {
    type:"text",
    fieldName:"salesman",
    title:"Salesman",
    value:"",
    placeholder:"Salesman",
    // isRequired:true,
    minLength:20,
  },
  {
    type:"row",
    items:[
      {
        type:"text",
        fieldName:"first",
        title:"First",
        placeholder:"First",
        isRequired:true,
      },
      {
        type:"text",
        fieldName:"middle",
        title:"Middle",
        placeholder:"Middle",
        isRequired:true,
      },
      {
        type:"text",
        fieldName:"last",
        title:"Last",
        placeholder:"Last",
        isRequired:true,
        // maxLength:10,
      },
      {
        type:"select",
        specialType:'state-select',
        fieldName:"usstate",
        title:"State",
        isRequired:true,
      },
    ]
  },
  {
    type:"select",
    fieldName:"person",
    title:"Person",
    value:"",
    options:[
      {
        name:"jimmy",
        value:0,
      },
      {
        name:"Tammy",
        value:1,
      },
      {
        name:"Fabio",
        value:2,
      },
      {
        name:"FKLDFJD",
        value:3,
      },

    ],
  },

]}/> */}


const STATEOPTIONSLIST=[
    {
        name: "Alabama",
        value:"al",
    },
    {
        name: "Alaska",
        value:"ak",
    },
    {
        name: "Arizona",
        value:"az",
    },
    {
        name: "Arkansas",
        value:"ar",
    },
    {
        name: "California",
        value:"ca",
    },
    {
        name: "Colorado",
        value:"co",
    },
    {
        name: "Connecticut",
        value:"ct",
    },
    {
        name: "Delaware",
        value:"de",
    },
    {
        name: "District of Columbia",
        value:"dc",
    },
    {
        name: "Florida",
        value:"fl",
    },
    {
        name: "Guam",
        value:"gu",
    },
    {
        name: "Georgia",
        value:"ga",
    },
    {
        name: "Hawaii",
        value:"hi",
    },
    {
        name: "Idaho",
        value:"id",
    },
    {
        name: "Illinois",
        value:"il",
    },
    {
        name: "Indiana",
        value:"in",
    },
    {
        name: "Iowa",
        value:"ia",
    },
    {
        name: "Kansas",
        value:"ks",
    },
    {
        name: "Kentucky",
        value:"ky",
    },
    {
        name: "Louisiana",
        value:"la",
    },
    {
        name: "Maine",
        value:"me",
    },
    {
        name: "Maryland",
        value:"md",
    },
    {
        name: "Massachusetts",
        value:"ma",
    },
    {
        name: "Michigan",
        value:"mi",
    },
    {
        name: "Minnesota",
        value:"mn",
    },
    {
        name: "Mississippi",
        value:"ms",
    },
    {
        name: "Missouri",
        value:"mo",
    },
    {
        name: "Montana",
        value:"mt",
    },
    {
        name: "Nebraska",
        value:"ne",
    },
    {
        name: "Nevada",
        value:"nv",
    },
    {
        name: "New Hampshire",
        value:"nh",
    },
    {
        name: "New Jersey",
        value:"nj",
    },
    {
        name: "New Mexico",
        value:"nm",
    },
    {
        name: "New York",
        value:"ny",
    },
    {
        name: "North Carolina",
        value:"nc",
    },
    {
        name: "North Dakota",
        value:"nd",
    },
    {
        name: "Ohio",
        value:"oh",
    },
    {
        name: "Oklahoma",
        value:"ok",
    },
    {
        name: "Oregon",
        value:"or",
    },
    {
        name: "Pennsylvania",
        value:"pa",
    },
    {
        name: "Puerto Rico",
        value:"pr",
    },
    {
        name: "Rhode Island",
        value:"ri",
    },
    {
        name: "South Carolina",
        value:"sc",
    },
    {
        name: "South Dakota",
        value:"sd",
    },
    {
        name: "Tennessee",
        value:"tn",
    },
    {
        name: "Texas",
        value:"tx",
    },
    {
        name: "Utah",
        value:"ut",
    },
    {
        name: "Vermont",
        value:"vt",
    },
    {
        name: "Virginia",
        value:"va",
    },
    {
        name: "Virgin Islands",
        value:"vi",
    },
    {
        name: "Washington",
        value:"wa",
    },
    {
        name: "West Virginia",
        value:"wv",
    },
    {
        name: "Wisconsin",
        value:"wi",
    },
    {
        name: "Wyoming",
        value:"wy",
    },
    {
        name: "Other",
        value:"other",
    },
];
