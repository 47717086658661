import React from 'react';
import {
  DealerForm,
  Loader, 
  FlexRowStartStretch,
  FlexColumnStartStretch,
  BackButton,
} from "components";
import PropTypes from 'prop-types';
import { 
  withRouter,
} from "react-router-dom";

class DealerCreate extends React.Component{

  static propTypes={
    history:PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:false,

    };

  }


  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    return (
      this.state.loading ? <Loader/>:
        <FlexColumnStartStretch>
          <FlexRowStartStretch>
            <BackButton parentProps={this.props}/>
          </FlexRowStartStretch>
          <DealerForm history={this.props.history}/>
        </FlexColumnStartStretch>
    );
  }
}


export default withRouter(DealerCreate);
