import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import Theme from 'theme/main.theme.js';

// uses notistack: https://iamhosseindhv.com/notistack
// Adopts approach from this issue: https://github.com/iamhosseindhv/notistack/issues/30

const DOM_MOUNT_ID="snackbarhelper";

const notistackConfig={
    maxSnack:3,
    horizontal:"right",
    vertical:"bottom",
    autoHideDuration:5000,
};

class ToastInitializer extends React.Component{
    render(){
        return (
            <div id={DOM_MOUNT_ID}
            style={{
                width:0,
                minWidth:0,
                height:0,
                minHeight:0,
            }}
            >                
            </div>
        );
    }
}

class Toast{
    static default(message){
        Toast.toast(message, 'default');  
    }

    static success(message){
        Toast.toast(message, 'success');  
    }

    static error(message){
        Toast.toast(message, 'error');  
    }

    static warning(message){
        Toast.toast(message, 'warning');  
    }

    static info(message){
        Toast.toast(message, 'info');  
    }

    static toast(msg, variant) {
        const Display = withSnackbar(({ message, enqueueSnackbar }) => {
          enqueueSnackbar(message, { variant });
          return null;
        });
        const mountPoint = document.getElementById(DOM_MOUNT_ID);
        ReactDOM.render(
            <ThemeProvider theme={Theme.materialTheme}>
                <SnackbarProvider 
                maxSnack={notistackConfig.maxSnack} 
                anchorOrigin={{horizontal: notistackConfig.horizontal, vertical: notistackConfig.vertical}}
                autoHideDuration={notistackConfig.autoHideDuration}
                >
                    <Display message={msg} variant={variant} />
                </SnackbarProvider>
            </ThemeProvider>,
          mountPoint)
      }
}


export {ToastInitializer, Toast,};