import React from 'react';
import {
  DealerForm,
  Loader, 
  FlexRowStartStretch,
  FlexColumnStartStretch,
  BackButton,
} from "components";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";

class DealerEdit extends React.Component{

  static propTypes={
    history:PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:false,
      objectId:Navigation.getURLParam(this.props,"id"),
    };
  }


  componentDidMount(){

  }

  componentWillUnmount(){
  }


  render(){
    return (
      this.state.loading ? <Loader/>:
        <FlexColumnStartStretch>
          <FlexRowStartStretch>
            <BackButton parentProps={this.props}/>
          </FlexRowStartStretch>
          <DealerForm history={this.props.history} 
          edit={true}
          editObjectId={this.state.objectId}
          />
        </FlexColumnStartStretch>
    );
  }
}


export default withRouter(DealerEdit);
