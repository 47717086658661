import React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";

import JWT from "services/jwt.service";
import CurrentUser from "services/current-user.service";
import Navigation from "services/navigation.service";
import currentUser from "services/current-user.service.js";

import Home from "routes/home/home.route";
import ChartView from "routes/chart/chart.route";
import Memorials from "routes/memorials/memorials.route";
import OrderDetail from "routes/order-detail/order-detail.route";
import OrderCreate from "routes/order-create/order-create.route";
import OrderEdit from "routes/order-edit/order-edit.route";
import UserDetail from "routes/user-detail/user-detail.route";
import UserCreate from "routes/user-create/user-create.route";
import UserEdit from "routes/user-edit/user-edit.route";
import Sales from "routes/sales/sales.route";
import DealerDetail from "routes/dealer-detail/dealer-detail.route";
import DealerCreate from "routes/dealer-create/dealer-create.route";
import DealerEdit from "routes/dealer-edit/dealer-edit.route";
import ManageAccount from "routes/manage-account/manage-account.route";
import HomeIcon from '@material-ui/icons/Home';
import Theme from "theme/main.theme.js";
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessIcon from '@material-ui/icons/Business';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleIcon from '@material-ui/icons/People';
import Logo from "assets/img/logo_long_dark_bg.png";
import { red } from '@material-ui/core/colors';
import PublicIcon from '@material-ui/icons/Public';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const drawerWidth = 240;

const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // justifyContent: 'flex-end',
    paddingLeft:16,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerListItem: {
    color:"white",
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.selected,
    }
  },
  drawerListItemIcon: {
    color:"white",
  },
  drawerListItemIconSelected: {
    // color:"#439b73",
    color:Theme.secondaryColor,
  },
});

class Main extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loading:true,
      open:true,
      loggedIn:true,
      anchorEl:null,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);

  }

  componentDidMount(){

  }


  handleDrawerOpen(){
    this.setState({
      open:true,
    });
  }

  handleDrawerClose(){
    this.setState({
      open:false,
    });
  }

  handleMenuOpen(event){
    this.setState({
      anchorEl:event.currentTarget,
    });
  }

  handleMenuClose(){
    this.setState({
      anchorEl:null,
    });
  }

  
  render(){
    const { classes } = this.props;

    // if not logged in, send to register
    if(!this.state.loggedIn){
      return (<Redirect to={Navigation.paths.login} />);
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(
                classes.menuButton,
                this.state.open && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                flexGrow: 1
              }}
            >
              <Typography variant={"h1"}>
                {Navigation.getPathTitle(this.props, "Admin")}
              </Typography>
            </div>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenuOpen}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
              >
                {/* <MenuItem onClick={() => this.props.history.push(Navigation.paths.manageAccount)}>Profile</MenuItem> */}
                <MenuItem onClick={()=>this.props.history.push(Navigation.paths.login)}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <div
              style={{
                flexGrow: 1
              }}
            >
              <a href="https://aisoft.dev/" target="_blank" rel="noopener noreferrer">
              <img
                src={Logo}
                // onClick={() => this.props.history.push(Navigation.paths.home)}
                selected={Navigation.isInSubPath(
                  this.props,
                  Navigation.paths.home
                )}
                alt={"logo"}
                style={{
                  width: "118px",
                  height: "40px",
                  // objectFit: "cover",
                  cursor: "pointer"
                }}
              />
              </a>
            </div>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          {/* <Divider  /> */}
          <List>
            <ListItem
              button
              onClick={() => this.props.history.push(Navigation.paths.home)}
              selected={Navigation.isInSubPath(
                this.props,
                Navigation.paths.home
              )}
              className={classes.drawerListItem}
            >
              <ListItemIcon
                className={
                  Navigation.isInSubPath(this.props, Navigation.paths.home)
                    ? classes.drawerListItemIconSelected
                    : classes.drawerListItemIcon
                }
              >
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
              <ListItem
                button
                onClick={() =>
                  this.props.history.push(Navigation.paths.sales)
                }
                selected={Navigation.isInSubPath(
                  this.props,
                  Navigation.paths.sales
                )}
                className={classes.drawerListItem}
              >
                <ListItemIcon
                  className={
                    Navigation.isInSubPath(this.props, Navigation.paths.sales)
                      ? classes.drawerListItemIconSelected
                      : classes.drawerListItemIcon
                  }
                >
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary={"Sales"} />
              </ListItem>
              <ListItem
                button
                onClick={() => this.props.history.push(Navigation.paths.chart)}
                selected={Navigation.isInSubPath(
                  this.props,
                  Navigation.paths.chart
                )}
                className={classes.drawerListItem}
              >
                <ListItemIcon
                  className={
                    Navigation.isInSubPath(this.props, Navigation.paths.chart)
                      ? classes.drawerListItemIconSelected
                      : classes.drawerListItemIcon
                  }
                >
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primary={"Charts"} />
              </ListItem>
              <ListItem
                button
                onClick={() => this.props.history.push(Navigation.paths.memorials)}
                selected={Navigation.isInSubPath(
                  this.props,
                  Navigation.paths.memorials
                  )}
                className={classes.drawerListItem}
              >
                <ListItemIcon
                  className={
                    Navigation.isInSubPath(this.props, Navigation.paths.memorials)
                    ? classes.drawerListItemIconSelected
                      : classes.drawerListItemIcon
                  }
                >
                  <PublicIcon/>
                </ListItemIcon>
                <ListItemText primary={"Memorials"} />
              </ListItem>
            {/* <ListItem
              button
              onClick={() =>
                this.props.history.push(Navigation.paths.manageAccount)
              }
              selected={false}
              className={classes.drawerListItem}
            >
              <ListItemIcon
                className={
                  Navigation.isInSubPath(
                    this.props,
                    Navigation.paths.manageAccount
                  )
                    ? classes.drawerListItemIconSelected
                    : classes.drawerListItemIcon
                }
              >
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary={"Account"} />
            </ListItem> */}
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: this.state.open
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route path={Navigation.paths.home}>
              <Home />
            </Route>
            <Route path={Navigation.paths.manageAccount}>
              <ManageAccount />
            </Route>
            <Route path={Navigation.paths.userCreate}>
              <UserCreate />
            </Route>
            <Route path={Navigation.paths.userEdit}>
              <UserEdit />
            </Route>
            <Route path={Navigation.paths.userDetail}>
              <UserDetail />
            </Route>
            <Route path={Navigation.paths.chart}>
              <ChartView />
            </Route>
            <Route path={Navigation.paths.orderCreate}>
              <OrderCreate />
            </Route>
            <Route path={Navigation.paths.orderEdit}>
              <OrderEdit />
            </Route>
            <Route path={Navigation.paths.orderDetail}>
              <OrderDetail />
            </Route>
            <Route path={Navigation.paths.memorials}>
              <Memorials />
            </Route>
            <Route path={Navigation.paths.dealerCreate}>
              <DealerCreate />
            </Route>
            <Route path={Navigation.paths.dealerEdit}>
              <DealerEdit />
            </Route>
            <Route path={Navigation.paths.dealerDetail}>
              <DealerDetail />
            </Route>
            <Route path={Navigation.paths.sales}>
              <Sales />
            </Route>
            <Route>
              <Redirect to={Navigation.paths.home} />
            </Route>
          </Switch>
        </main>
      </div>
    );
  }
}


export default withStyles(useStyles)(withRouter(Main));