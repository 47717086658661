
import HTTPService from "services/http.service";
import JWT from "services/jwt.service";

class currentUser{
    
    static key = "currentUser";

    static async pullCurrentUser(){
        let currUser = await HTTPService.getUser(JWT.getUserID(),null);        
        currentUser.storeCurrentUser(currUser);
        return;
    }
    
    static storeCurrentUser(currUserObject) {
        localStorage.setItem(currentUser.key,JSON.stringify(currUserObject));
        return;
    }

    static getUserFromStorage() {        
        return JSON.parse(localStorage.getItem(currentUser.key)) || {};
    }
  
    static clearCurrentUser() {
        localStorage.removeItem(currentUser.key);
        return;
    }
}
  
export default currentUser;