import React from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import {
  // TableContainer,
  // Table,
  // TableHead,
  // TableRow,
  // TableCell,
  // TableBody,
  // Paper,
  Container,
  Button,
  IconButton,
} from '@material-ui/core';
// https://material-table.com/#/docs/get-started
import MaterialTable, { MTableToolbar, MTableAction } from 'material-table';
import Theme from "theme/main.theme.js";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";



class DataTable extends React.Component{
  constructor(props) {
    super(props);
    this.state={
    };
  }
  
    static propTypes={
      title: PropTypes.string,
      columns: PropTypes.array,
      data: PropTypes.array,
      showRowEditButton: PropTypes.bool,
      onEditButtonClick: PropTypes.func,      
      isLoading: PropTypes.bool,
      actionsColumnFirst: PropTypes.bool,
      addPrimaryAddButton: PropTypes.bool,
      onAddButtonClick: PropTypes.func,
      onAddButtonText: PropTypes.string,
    };


    render(){
      // The way material table works, it will automatically assign the formating of an iconbutton this can be soled by adding individual actions
              let actions = [];
              if (this.props.showRowEditButton) {
                actions.push({
                  icon: "edit",
                  onClick: this.props.onEditButtonClick,
                  tooltip: "Edit"
                });
              }
              if (this.props.addPrimaryAddButton) {
                actions.push({
                  isFreeAction: true,
                  onClick: this.props.onAddButtonClick,
                  icon: "add"
                });
              }
              return (
                <MaterialTable
                  columns={this.props.columns}
                  data={this.props.data}
                  title={this.props.title}
                  actions={actions}
                  isLoading={this.props.isLoading}
                  components={{
                    Action: props => {
                      console.log(props)
                          if(props.action.icon === "add"){
                            return (
                              <Button
                                component={"div"}
                                onClick={() => this.props.onAddButtonClick()}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                              >
                                {this.props.onAddButtonText}
                              </Button>
                            );
                      } 
                      if (props.action.icon === "edit") {
                        return (
                          // <MTableAction icon={}/>
                          <IconButton
                            onClick={() => this.props.onEditButtonClick(null, props.data)}
                          >
                            <EditIcon />
                          </IconButton>
                        );
                      } 
                    }
                    
                  }}
                  options={{
                    actionsColumnIndex: this.props.actionsColumnFirst ? 0 : -1,
                    exportButton: true,
                    search: true,
                    selection: false,
                    filtering: false,
                    sorting: true,
                    exportAllData: false,
                    paging: true,
                    searchFieldAlignment: "right",
                    draggable: true,
                    toolbar: true
                  }}
                />
              );
            }
}

export {DataTable};