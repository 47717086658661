import React from 'react';
import PropTypes from 'prop-types';
import { 
  withRouter,
} from "react-router-dom";
import {CustomForm, CRUDForm} from "./forms.component.js";
import HTTPService from "services/http.service.js";
import Navigation from "services/navigation.service.js";
import { fontSize } from '@material-ui/system';
import currentUser from "services/current-user.service.js"

class DealerForm extends React.Component{
    static propTypes={
      history:PropTypes.object.isRequired,
      edit:PropTypes.bool,
      editObjectId:PropTypes.string,
    };
    
    constructor(props) {
        super(props);
        this.state={
        };

        this.addObject = this.addObject.bind(this);
    }

    componentDidMount(){
      let currUser = currentUser.getUserFromStorage();
    }
  
    componentWillUnmount(){
    }


    async addObject(json,history){
      json = {
        ...json,
        is_dealer:true,
      };
      return HTTPService.newOrganization(json,history);
    }

    render(){

        return (
          <CRUDForm
            history={this.props.history}
            edit={this.props.edit}
            editObjectId={this.props.editObjectId}
            editGetFunction={HTTPService.getOrganization}
            postFunction={this.addObject}
            putFunction={HTTPService.editOrganization}
            showDelete={true}
            deletionFunction={HTTPService.deleteOrganization}
            submissionSuccessRedirectFunction={() =>
              this.props.history.push(Navigation.paths.sales)
            }
            deletionSuccessRedirectFunction={() =>
              this.props.history.push(Navigation.paths.sales)
            }
            deleteDialogTitle={"Delete Dealer?"}
            deleteDialogBody={
              "This will delete all users associated with this dealer and cannot be undone."
            }
            formSchema={[
              {
                type: "text",
                fieldName: "name",
                title: "Location Name*",
                placeholder: "Name",
                isRequired: true,
                maxLength: 254
              },
              {
                type: "text",
                fieldName: "street_address",
                title: "Street Address",
                placeholder: "Street Address",
                maxLength: 254
              },
              {
                type: "row",
                items: [
                  {
                    type: "text",
                    fieldName: "city",
                    title: "City",
                    placeholder: "City",
                    maxLength: 254
                  },
                  {
                    type: "select",
                    specialType: "state-select",
                    fieldName: "state",
                    title: "State"
                  },
                  {
                    type: "text",
                    specialType: "zipcode",
                    fieldName: "zipcode",
                    title: "Zipcode",
                    placeholder: "Zipcode"
                  }
                ]
              },
              {                
                type: "number",
                fieldName: "sales_tax",
                minValue: 0.00,
                maxValue: 1.00,
                step: 0.01,
                value: 0,
                title: "Sales Tax Rate",
                // style: {width: 150, fontSize: 30}
              }
            ]}
          />
        );
    }
}


export {DealerForm};