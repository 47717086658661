import React from 'react';
import PropTypes from 'prop-types';
import HTTPService from "services/http.service";
import Toast from "services/toast.service";
import Navigation from "services/navigation.service.js";
import {PrimaryButton, 
  CircleButton, 
  Loader, 
  ConfirmDialog, 
  InputDialog,
  CustomTextArea,
  EmailInput,
  PasswordInput,
  InputErrorMessage,
  FlexRowCenterCenter,
  FlexColumnStartStretch,
  FlexColumnStartCenter,
  FlexColumnEndCenter,
  Card,
  RouteCard,
} from "components";
import { 
  withRouter,
} from "react-router-dom";
import BusinessIcon from '@material-ui/icons/Business';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import PublicIcon from '@material-ui/icons/Public';
import {
  Typography,
} from '@material-ui/core';
import Theme from "theme/main.theme.js";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

class Home extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loading:false,
    };

  }
  
  static propTypes={
    history: PropTypes.object.isRequired,
  };
  
  componentDidMount(){
    
  }

  componentWillUnmount(){
  }



  render(){
    let color1 = Theme.primaryColor;
    let color2 = "#1c0a3cc7";
    return (
      <FlexRowCenterCenter spacing={10} style={{
        paddingTop:20
      }}>
        {/* <RouteCard
        title={"Home"}
        icon={<HomeIcon style={{ fontSize: 80 }}/>}
        onClick={()=>this.props.history.push(Navigation.paths.home)}
        gradientColor1={"rgba(39,52,105,1)"}
        gradientColor2={"rgba(33,58,159,1)"}
        /> */}
        <RouteCard
        title={"Sales"}
        icon={<AttachMoneyIcon  style={{ fontSize: 80 }}/>}
        onClick={()=>this.props.history.push(Navigation.paths.sales)}
        gradientColor1={color1}
        gradientColor2={color2}
        />
        <RouteCard
        title={"Charts"}
        icon={<TrendingUpIcon  style={{ fontSize: 80 }}/>}
        onClick={()=>this.props.history.push(Navigation.paths.chart)}
        gradientColor1={color1}
        gradientColor2={color2}
        />
        <RouteCard
        title={"Memorials"}
        icon={<PublicIcon style={{ fontSize: 80 }}/>}
        onClick={()=>this.props.history.push(Navigation.paths.memorials)}
        gradientColor1={color1}
        gradientColor2={color2}
        />
        {/* <RouteCard
        title={"Account"}
        icon={<AccountBoxIcon  style={{ fontSize: 80 }}/>}
        onClick={()=>this.props.history.push(Navigation.paths.manageAccount)}
        gradientColor1={color1}
        gradientColor2={color2}
        /> */}


      </FlexRowCenterCenter>
    );
  }
}

export default withRouter(Home);
