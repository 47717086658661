import React from 'react';
import {
  OrderForm,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  BackButton,
} from "components";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";
import HTTPService from "services/http.service.js";
import { 
  CircularProgress,
} from '@material-ui/core';
import { FlexRowCenterCenter } from '../../components/flex.component';

class OrderEdit extends React.Component{

  static propTypes={
    history:PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:true,
      objectId:Navigation.getURLParam(this.props,"id"),
      initialData:null,
    };

    this.pullData=this.pullData.bind(this);
  }

  componentDidMount(){
    this.pullData();
  }

  componentWillUnmount(){
  }

  async pullData(){
    this.setState({loading:true});
    
    let initialData = await HTTPService.getOrder(this.state.objectId,this.props.history);
    console.log(initialData);

    this.setState({initialData:initialData,loading:false});
  }

  render(){
    return (
        <FlexColumnStartStretch>
          <FlexRowStartStretch>
            <BackButton parentProps={this.props}/>
          </FlexRowStartStretch>
          <FlexRowCenterCenter>
            {this.state.loading && <CircularProgress color="secondary" size={50}/>}
          </FlexRowCenterCenter>
          {!this.state.loading &&
            <OrderForm history={this.props.history} 
            edit={true}
            editObjectId={this.state.objectId}
            initialData={this.state.initialData}
            showDelete={true}
            deletionFunction={HTTPService.deleteOrder}
            deletionSuccessRedirectFunction={() =>
              this.props.history.push(Navigation.paths.orders)
            }
            deleteDialogTitle={"Delete Order?"}
            deleteDialogBody={
              "This will delete this order and cannot be undone."
            }
            />
          }
        </FlexColumnStartStretch>
    );
  }
}


export default withRouter(OrderEdit);


