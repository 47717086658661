import React from 'react';
import PropTypes from 'prop-types';
import HTTPService from "services/http.service";
import Toast from "services/toast.service";
import { CustomForm, CRUDForm } from "components/forms.component.js";
import {
  UserForm,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  BackButton
} from "components";
import Navigation from "services/navigation.service.js";
import { withRouter } from "react-router-dom";
import currentUser from "services/current-user.service.js";
import { Typography } from "@material-ui/core";
import JWT from "services/jwt.service";
import CurrentUser from "services/current-user.service";


class ManageAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      objectId: currentUser.getUserFromStorage().firebase_id
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {}

  componentWillUnmount() {}

  handleLogout() {
    JWT.clearToken();
    CurrentUser.clearCurrentUser();
  }

  render() {
    return (
      <FlexColumnStartStretch>
        <FlexRowStartStretch>
          <BackButton parentProps={this.props} />
        </FlexRowStartStretch>
        <UserForm
          history={this.props.history}
          edit={true}
          editObjectId={this.state.objectId}
          selfEdit={true}
        />
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Typography
            variant="h1"
            style={{
              color: "black",
              marginTop:10,
              marginBottom:20,
            }}
          >
            Change Password
          </Typography>
        </div>
        <CRUDForm
          history={this.props.history}
          edit={true}
          editObjectId={this.state.objectId}
          editGetFunction={currentUser.getUserFromStorage}
          putFunction={HTTPService.changePassword}
          showDelete={false}
          changePassword={true}
          submissionSuccessRedirectFunction={() => this.handleLogout()}
          formSchema={[
            {
              type: "password",
              fieldName: "new_password",
              title: "New Password",
              placeholder: "New Password",
              isRequired: true,
              minLength:8,
              maxLength: 254,
            }
          ]}
        />
      </FlexColumnStartStretch>
    );
  }
}

export default withRouter(ManageAccount);


// import React from "react";
// import {
//   UserForm,
//   FlexRowStartStretch,
//   FlexColumnStartStretch,
//   BackButton
// } from "components";
// import PropTypes from "prop-types";
// import Navigation from "services/navigation.service.js";
// import { withRouter } from "react-router-dom";

// class UserEdit extends React.Component {
//   static propTypes = {
//     history: PropTypes.object.isRequired
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       objectId: Navigation.getURLParam(this.props, "id")
//     };
//   }

//   componentDidMount() {}

//   componentWillUnmount() {}

//   render() {
//     return (
//       <FlexColumnStartStretch>
//         <FlexRowStartStretch>
//           <BackButton parentProps={this.props} />
//         </FlexRowStartStretch>
//         <UserForm
//           history={this.props.history}
//           edit={true}
//           editObjectId={this.state.objectId}
//         />
//       </FlexColumnStartStretch>
//     );
//   }
// }

// export default withRouter(UserEdit);