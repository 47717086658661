import React from 'react';
import PropTypes from 'prop-types';
import HTTPService from "services/http.service";
import {
  FlexColumnStartStretch,
  FlexRowCenterStretch,
  DataTable,
} from "components";
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";
import { 
  Container,
  Grid,
  Chip,
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
 } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LanguageIcon from '@material-ui/icons/Language';
import { GoogleMap, LoadScript, MarkerClusterer, Marker} from '@react-google-maps/api'
import Theme from "theme/main.theme.js";
import CustomMarker from "assets/img/marker.png";
import RoomIcon from '@material-ui/icons/Room';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

const BounceAnimation=1;
const DropAnimation=2;

class Memorials extends React.Component{
  static propTypes={
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:false,
      locations:this.originalLocations,
      fdr:true,
      kwm:true,
      lim:true,
      mlk:true,
      tjm:true,
      vvm:true,
      wam:true,
      wwm:true,
      fdrBounce:false,
      kwmBounce:false,
      limBounce:false,
      mlkBounce:false,
      tjmBounce:false,
      vvmBounce:false,
      wamBounce:false,
      wwmBounce:false,
      initialLoad:true,
    };

    this.pullData = this.pullData.bind(this);
  }
  
  componentDidMount(){
    this.pullData();
    this.setState({initialLoad:false});
    
  }

  componentWillUnmount(){
  }

  async pullData(){
  }
  
  containerStyle = {
    width: '100%',
    height: '100%',
  };

  center = {
    lat: 38.885231, 
    lng: -77.039930
  };
  originalLocations = [
    { lat: 38.882923, lng: -77.042380, name:"FDR Memorial", id:"fdr", url:"https://www.nps.gov/frde"},//Franklin Delano Roosevelt Memorial
    { lat: 38.887797, lng: -77.047682, name: "Korean War Veterans Memorial", id:"kwm", url:"https://www.nps.gov/kowa"},//Korean War Veterans Memorial
    { lat: 38.889210, lng: -77.050155, name: "Lincoln Memorial", id:"lim", url:"https://www.nps.gov/linc"},//Lincoln Memorial
    { lat: 38.886191, lng: -77.044215, name: "MLK, Jr. Memorial", id:"mlk", url:"https://www.nps.gov/mlkm"},//Martin Luther King, Jr. Memorial
    { lat: 38.881247, lng: -77.036550, name: "Thomas Jefferson Memorial", id:"tjm", url:"https://www.nps.gov/thje"},//Thomas Jefferson Memorial
    { lat: 38.891277, lng: -77.047681, name: "Vietnam Veterans Memorial", id:"vvm", url:"https://www.nps.gov/vive/index.htm"},//Vietnam Veterans Memorial
    { lat: 38.889462, lng: -77.035171, name: "Washington Monument", id:"wam", url:"https://www.nps.gov/wamo"},//Washington Monument
    { lat: 38.889402, lng: -77.040499, name: "World War II Memorial", id:"wwm", url:"https://www.nps.gov/wwii"},//World War II Memorial
  ]
  
  options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  }
  
  createKey(location){
    return location.lat + location.lng
  }

  handleChange(event,id){
    let checked = event.target.checked;
    this.setState({
      [id]:checked,
    });
  }

  render(){
    return (
      <FlexRowCenterStretch style={{
        height:`calc(100% - 64px)`,
      }}>
        <LoadScript
        googleMapsApiKey="AIzaSyC684N-tEsd2J6UT7OZOWy_m1WidWJqK3c"
        loadingElement={<CircularProgress color="secondary" />}
      >
        <GoogleMap
          mapContainerStyle={this.containerStyle}
          center={this.center}
          zoom={16}
          clickableIcons={false}
          options={{ styles:mapStyle,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}

        >
        {this.state.locations.filter((location)=>this.state[location.id]).map((location)=>(
            <Marker 
            key={this.createKey(location)} 
            position={location} 
            animation={DropAnimation}
            onMouseOver={()=>this.setState({[`${location.id}Bounce`]:true})}
            onMouseOut={()=>this.setState({[`${location.id}Bounce`]:false})}
            onClick={()=>window.open(location.url, "_blank")}
            icon={{
              url:CustomMarker,
              labelOrigin:{x:15,y:this.state[`${location.id}Bounce`]? -25:-15},
              // origin:{x:0,y:this.state[`${location.id}Bounce`]? 1:0},
              // size:{width:100,height:this.state[`${location.id}Bounce`]? 200:100},
              // scaledSize:{width:100,height:100},
            }}
            label={{
              text:location.name,
              color: Theme.secondaryColor,
              fontFamily:"'Jura', sans-serif",
              fontSize: this.state[`${location.id}Bounce`] ? "20px":"18px",
              fontWeight: "bold",
            }}
            />
        ))

        }
        </GoogleMap>
      </LoadScript>
        <div style={{
          position:"absolute",
          zIndex:20,
          color:"white",
          right:0,
          padding:"10px",
        }}>
          <Card >
            <CardContent style={{
              display:"flex",
              flexDirection:"column",
            }}>
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.fdr}
                  onChange={(event)=> this.setState({
                    fdr:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="FDR Memorial"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.kwm}
                  onChange={(event)=> this.setState({
                    kwm:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="Korean War Veterans Memorial"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.lim}
                  onChange={(event)=> this.setState({
                    lim:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="Lincoln Memorial"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.mlk}
                  onChange={(event)=> this.setState({
                    mlk:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="MLK, Jr. Memorial"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.tjm}
                  onChange={(event)=> this.setState({
                    tjm:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="Thomas Jefferson Memorial"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.vvm}
                  onChange={(event)=> this.setState({
                    vvm:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="Vietnam Veterans Memorial"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.wam}
                  onChange={(event)=> this.setState({
                    wam:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="Washington Monument"
              />
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.wwm}
                  onChange={(event)=> this.setState({
                    wwm:event.target.checked,
                  })}
                  icon={<RoomOutlinedIcon />} 
                  checkedIcon={<RoomIcon />} 
                  />}
                label="World War II Memorial"
              />
            </CardContent>
            </Card>
        </div>
      </FlexRowCenterStretch>
    );
  }
}


export default withRouter(Memorials);

const mapStyle=[
  {
    "elementType": "geometry",
    "stylers": [
      {
        // "color": Theme.secondaryColor
        "color": "#1d2c4d"
        // "color": "#ffffff"
      }
    ]
  },
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8ec3b9"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1a3646"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b6878"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#64779e"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b6878"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        // "color": "#334e87"
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
        // "color": Theme.secondaryColor
        // "color": "#023e58"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#283d6a"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6f9ba5"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#023e58"
        // "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3C7680"
      }
    ]
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        // "color": "#304a7d"
        "color": Theme.primaryColor 
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98a5be"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        // "color": "#2c6675"
        "color": Theme.primaryColor 
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#255763"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98a5be"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98a5be"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#283d6a"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3a4762"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
        // "color": Theme.primaryColor                   
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#4e6d70"
      }
    ]
  }
];