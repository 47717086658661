class URLService{
    // change host based on dev vs prod
    static developmentHost = "http://0.0.0.0:8080";
    static productionHost = "https://aisoft.dev";

    static host = process.env.NODE_ENV === 'production' ? URLService.productionHost:URLService.developmentHost;

    static apiBase ="api";
    static authBase ="auth";

    static login = () => `${URLService.host}/${URLService.apiBase}/${URLService.authBase}/login`;
    static forgotPassword = () => `${URLService.host}/${URLService.apiBase}/${URLService.authBase}/forgot-password`;
    static logout= () => `${URLService.host}/${URLService.apiBase}/${URLService.authBase}/logout`;
    static changePassword = () => `${URLService.host}/${URLService.apiBase}/${URLService.authBase}/change-password`;
    static refreshToken= () => `${URLService.host}/${URLService.apiBase}/${URLService.authBase}/refresh-token`;


    static cocktails = () =>`http://my-json-server.typicode.com/teddy-owen/json-placeholder/posts`;
    
    
}

export default URLService;

