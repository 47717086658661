import React from 'react';
import {
  UserForm,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  BackButton,
} from "components";
import PropTypes from 'prop-types';
import { 
  withRouter,
} from "react-router-dom";

class UserCreate extends React.Component{

  static propTypes={
    history:PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
    };

  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    return (      
        <FlexColumnStartStretch>
          <FlexRowStartStretch>
            <BackButton parentProps={this.props}/>
          </FlexRowStartStretch>
          <UserForm history={this.props.history}/>
        </FlexColumnStartStretch>
    );
  }
}


export default withRouter(UserCreate);
