import React from 'react';
import ReactDOM from 'react-dom';
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Auth from './routes/auth/auth.route.js';
import Main from './routes/main/main.route.js';
import Landing from './routes/landing/landing.route.js';
import * as serviceWorker from './serviceWorker';
import './index.css';

import Navigation from "services/navigation.service.js";
import {ToastInitializer} from "services/toast.service.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import Theme from 'theme/main.theme.js';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjsUtils from '@date-io/dayjs';

ReactDOM.render(
<App/>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function App(){
    return(
        <React.Fragment>
            <CssBaseline /> {/* Per Material UI: https://material-ui.com/components/css-baseline/ */}
            <ThemeProvider theme={Theme.materialTheme}> {/* Per Material UI: https://material-ui.com/customization/theming/ */}                    
                <ToastInitializer/>
                <MuiPickersUtilsProvider utils={dayjsUtils}>
                    <Router>
                        <Switch >
                            <Route path={Navigation.paths.auth}>
                                <Auth/>
                            </Route>
                            <Route path={Navigation.paths.app}>
                                <Main/> 
                            </Route>
                            {/* <Route path={Navigation.paths.root} exact={true}>
                                <Landing/>
                            </Route> */}
                            <Route>
                                <Redirect to={Navigation.paths.app} />
                            </Route>
                        </Switch>
                    </Router>                            
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </React.Fragment>
    );
}