import URLService from "services/url.service.js";
import JWT from "services/jwt.service.js";
import CurrentUser from "services/current-user.service";
import Navigation from "services/navigation.service.js";

class HTTPService{
    static async _httpGET(url,history=null){
        // Check if token expired, and refresh if so
        await HTTPService.checkAndRefreshToken(history);

        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            "content-type":"application/json", 
            'Accept': 'application/json',
        });
        
        let response = await fetch(url,{
            method: 'GET',
            headers: headers,
          });
        
        if(response.ok){
            let responseJson = await response.json();
            return responseJson;
        }else if(response.status === 401 || response.status === 403){
            // Redirect to login
            throw response;
        }else{
            throw response;
        }

    }

    static async _httpPOST(url,json,checkToken=true,logoutOn401=true,history=null){
        // Check if token expired, and refresh if so
        if(checkToken){
            await HTTPService.checkAndRefreshToken(history);
        }
    
        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            "content-type":"application/json", 
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'POST',
            headers: headers,
            body:JSON.stringify(json),
          });
        
        if(response.ok){
            try {                
                let responseJson = await response.json();
                return responseJson;
            } catch (error) {
                return;
            }
        }else if((response.status === 401 || response.status === 403) && logoutOn401){
            // Redirect to login
            throw response;
        }else{
            throw response;
        }

    }

    static async _httpPUT(url,json,checkToken=true,logoutOn401=true,history=null){
        // Check if token expired, and refresh if so
        if(checkToken){
            await HTTPService.checkAndRefreshToken(history);
        }
    
        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            "content-type":"application/json", 
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'PUT',
            headers: headers,
            body:JSON.stringify(json),
          });
        
        if(response.ok){
            try {                
                let responseJson = await response.json();
                return responseJson;
            } catch (error) {
                return;
            }
        }else if((response.status === 401 || response.status === 403) && logoutOn401){
            // Redirect to login
            throw response;
        }else{
            throw response;
        }

    }
    
    static async _httpDELETE(url,history=null){
        // Check if token expired, and refresh if so
        await HTTPService.checkAndRefreshToken(history);
        
        let headers = new Headers({
            "Authorization":`token ${JWT.getTokenFromStorage()}`,
            'Accept': 'application/json',
        });

        let response = await fetch(url,{
            method: 'DELETE',
            headers: headers,
          });
        
        if(response.ok){
            try {                
                let responseJson = await response.json();
                return responseJson;
            } catch (error) {
                return;
            }
        }else if(response.status === 401 || response.status === 403){
            // Redirect to login
            throw response;
        }else{
            throw response;
        }

    }
    
    // Users
    static async getUsers(history) {
        let response = await HTTPService._httpGET(URLService.users(),history);
        return response;
      }

    static async getUser(id,history) {
        let response = await HTTPService._httpGET(URLService.user(id),history);
        return response;
      }
    
    static async newUser(data,history) {
        let response = await HTTPService._httpPOST(URLService.users(),data,history);
        return response;
    } 

    static async editUser(id,data,props) {
        let response = await HTTPService._httpPUT(URLService.user(id),data,props);
        return response;
    } 

    static async deleteUser(id,history) {
        let response = await HTTPService._httpDELETE(URLService.user(id),history);
        return response;
    } 

    // Organizations
    static async getOrganizations(history) {
        let response = await HTTPService._httpGET(URLService.organizations(),history);
        return response;
      }

    static async getOrganization(id,history) {
        let response = await HTTPService._httpGET(URLService.organization(id),history);
        return response;
      }
    
    static async newOrganization(data,history) {
        let response = await HTTPService._httpPOST(URLService.organizations(),data,history);
        return response;
    } 

    static async editOrganization(id,data,history) {
        let response = await HTTPService._httpPUT(URLService.organization(id),data,history);
        return response;
    } 

    static async deleteOrganization(id,history) {
        let response = await HTTPService._httpDELETE(URLService.organization(id,history));
        return response;
    } 

    // Orders
    static async getOrders(history) {
        let response = await HTTPService._httpGET(URLService.orders(),history);
        return response;
      }

    static async getOrder(id,history) {
        let response = await HTTPService._httpGET(URLService.order(id),history);
        return response;
      }
    
    static async newOrder(data,history) {
        let response = await HTTPService._httpPOST(URLService.orders(),data,history);
        return response;
    } 

    static async editOrder(id,data,history) {
        let response = await HTTPService._httpPUT(URLService.order(id),data,history);
        return response;
    } 

    static async deleteOrder(id,history) {
        let response = await HTTPService._httpDELETE(URLService.order(id),history);
        return response;
    } 

    static async sendDocusign(id,data,history) {
        let response = await HTTPService._httpPOST(URLService.orderDocusign(id),data,history);
        return response;
    } 
    
    // Payments
    static async getPaymentSecret(data,history) {
        let response = await HTTPService._httpPOST(URLService.payments(),data,history);
        return response;
    } 

    // Auth
    static async login(email, password){
        let response = await HTTPService._httpPOST(URLService.login(),{email:email,password:password},false,false);
        return response;
    }

    static async logout(){
        let response = await HTTPService._httpPOST(URLService.logout(),{});
        return response;
    }

    static async register(email, password){
        let response = await HTTPService._httpPOST(URLService.users(),{email:email,password:password},false,false);
        return response;
    }

    static async forgotPassword(email){
        let response = await HTTPService._httpPOST(URLService.forgotPassword(),{email:email},false,false);
        return response;
    }

    static async changePassword(password){
        let response = await HTTPService._httpPUT(URLService.changePassword(),{"new_password":password});
        return response;
    }

    static async updateEmail(email){
        let response = await HTTPService._httpPUT(URLService.user(JWT.getUserID()),{email});
        return response;
    }

    static async checkAndRefreshToken(history){
        if(JWT.isTokenExpired()){
          console.log("token expired, refreshing");
          try {
              let responseJson = await HTTPService._httpPOST(URLService.refreshToken(),{},false,false);
              JWT.storeToken(responseJson["token"]);
              await CurrentUser.pullCurrentUser();
          } catch (error) {
                console.error(error);  
          }
        }
        return;
    }


    static async getCocktails() {
        let response = await HTTPService._httpGET(URLService.cocktails());
        return response;
    }
    
}

export default HTTPService;