import React from 'react';
import {
  UserForm,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  BackButton,
} from "components";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";

class UserEdit extends React.Component{

  static propTypes={
    history:PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      objectId:Navigation.getURLParam(this.props,"id"),
    };
  }

  componentDidMount(){

  }

  componentWillUnmount(){
  }


  render(){
    return (
        <FlexColumnStartStretch>
          <FlexRowStartStretch>
            <BackButton parentProps={this.props}/>
          </FlexRowStartStretch>
          <UserForm history={this.props.history} 
          edit={true}
          editObjectId={this.state.objectId}
          />
        </FlexColumnStartStretch>
    );
  }
}


export default withRouter(UserEdit);
