import React from 'react';
import PropTypes from 'prop-types';
import {Elements,ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { 
  Button, 
  CircularProgress,
  Typography,
} from '@material-ui/core';
import HTTPService from "services/http.service.js";
import {
  CustomCurrencyInput
} from "./inputs.component.js";
import {
  FlexColumnStartStretch,
} from "./flex.component.js";
import Theme from 'theme/main.theme.js';
import {currencyFormat} from "services/helpers.service";
import DoneIcon from '@material-ui/icons/Done';
import {Toast} from "services/toast.service";

class Payment extends React.Component{
  testKey = "pk_test_o4tgZr5jbakYTeoNErY3Bu1n";
  liveKey = "pk_live_lfIaqNw870AAxY2e3iZR7cyB";
  currentKey = process.env.NODE_ENV === 'production' ? this.liveKey:this.testKey;
  stripePromise = loadStripe(this.currentKey);


  constructor(props) {
      super(props);
      this.state={
      };
      
  }
  
  static propTypes={
    history:PropTypes.object.isRequired,
    orderId:PropTypes.any,
    name:PropTypes.string,
    email:PropTypes.string,
    onSuccess:PropTypes.func,
    amountCharged:PropTypes.any,
  };

  render(){
      return (
      <Elements stripe={this.stripePromise}>
        <ElementsConsumer>
          {({stripe, elements}) => (            
            <CheckoutForm  
            stripe={stripe} 
            elements={elements}
            history={this.props.history} 
            orderId={this.props.orderId}
            name={this.props.name}
            email={this.props.email}
            onSuccess={this.props.onSuccess}
            amountCharged={this.props.amountCharged}
            />
          )}
        </ElementsConsumer>
      </Elements>
      );
  }
}

class CheckoutForm extends React.Component {
  static propTypes={
    history:PropTypes.object.isRequired,
    orderId:PropTypes.any,
    name:PropTypes.string,
    email:PropTypes.string,
    phone:PropTypes.string,
    onSuccess:PropTypes.func,
    amountCharged:PropTypes.any,
  };
  
  constructor(props) {
    super(props);
    this.state={
      loading:false,
      success:false,
      errorMessage:"",
      toCharge:"",
      confirmCharge:"",
      amountCharged:props.amountCharged || "",
    };

    this.handleSubmit=this.handleSubmit.bind(this);
  }

  handleSubmit = async (event) => {
    this.setState({
      loading:true,
      errorMessage:"",
    });
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    
    let data = {
      amount:this.state.toCharge,
      order_id:this.props.orderId,
      name:this.props.name,
      email:this.props.email,
      phone:this.props.phone,
    };
    let response;
    let result;
    try {      
      response = await HTTPService.getPaymentSecret(data,this.props.history);
      result = await stripe.confirmCardPayment(response.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: this.props.name,
          },
        },
        receipt_email:this.props.email,
      });
    } catch (error) {
      this.setState({
        loading:false,
        errorMessage:"Transaction did not succeed."
      });
      throw error;
    }

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      this.setState({
        errorMessage:result.error.message,
      });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        let paymentAmount = result.paymentIntent.amount;
        let paymentIntentId = result.paymentIntent.id;
        this.props.onSuccess(paymentAmount,paymentIntentId);
        this.setState({
          success:true,
          amountCharged:paymentAmount,
        });
      } else{
        this.setState({
          errorMessage:"Transaction did not succeed."
        });
      }
    }

    this.setState({loading:false});
  };

  render() {
    return (
      <FlexColumnStartStretch>
        {this.state.amountCharged > 0 && (
          <Typography
            variant="h1"
            style={{
              textAlign: "center"
            }}
          >
            Charged {currencyFormat(this.state.amountCharged / 100)}
          </Typography>
        )}
        {!(this.state.amountCharged > 0) && (
          <CustomCurrencyInput
            value={this.state.toCharge}
            onChange={(value, isValid) => this.setState({ toCharge: value })}
            title={"Charge Amount"}
            validateAlways={true}
            icon={"$"}
            minValue={0}
          />
        )}
        {!(this.state.amountCharged > 0) && (
          <CustomCurrencyInput
            style={{
              marginTop: 20
            }}
            value={this.state.confirmCharge}
            onChange={(value, isValid) =>
              this.setState({ confirmCharge: value })
            }
            title={"Confirm Charge Amount"}
            validateAlways={true}
            icon={"$"}
            minValue={0}
          />
        )}
        {this.state.toCharge !== this.state.confirmCharge && (
          <div
            style={{
              color: Theme.errorColor,
              marginTop: 7
            }}
          >
            Charge amounts must be equal
          </div>
        )}
        {!(this.state.amountCharged > 0) && (
          <div
            style={{
              marginTop: 30
            }}
          >
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4"
                    }
                  },
                  invalid: {
                    color: Theme.errorColor
                  }
                }
              }}
            />
          </div>
        )}
        {this.state.errorMessage && (
          <div
            style={{
              color: Theme.errorColor,
              marginTop: 7
            }}
          >
            {this.state.errorMessage}
          </div>
        )}
        <Button
          style={{
            marginTop: 20,
            backgroundColor: this.state.success && Theme.successColor
          }}
          variant="contained"
          color="secondary"
          startIcon={this.state.success && <DoneIcon />}
          disabled={
            !this.props.stripe ||
            !this.state.toCharge ||
            this.state.toCharge !== this.state.confirmCharge
          }
          fullWidth={true}
          onClick={
            this.state.loading || this.state.success
              ? () => {}
              : this.handleSubmit
          }
        >
          {this.state.loading ? (
            <CircularProgress color="secondary" size={25} />
          ) : this.state.success ? (
            `Card Charged ${currencyFormat(this.state.amountCharged / 100)}`
          ) : (
            `Charge Card ${currencyFormat(this.state.toCharge / 100)}`
          )}
        </Button>
      </FlexColumnStartStretch>
    );
  }
}

export {
  Payment,
};