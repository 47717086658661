import React from 'react';
import PropTypes from 'prop-types';
import { 
  withRouter,
} from "react-router-dom";
import {CustomForm, CRUDForm} from "./forms.component.js";
import HTTPService from "services/http.service.js";
import Navigation from "services/navigation.service.js";


class UserForm extends React.Component{
    static propTypes={
      history:PropTypes.object.isRequired,
      edit:PropTypes.bool,
      editObjectId:PropTypes.string,
      selfEdit:PropTypes.bool,
    };
    
    constructor(props) {
        super(props);
        this.state={
          organizations:[],
        };

        this.pullOrgs = this.pullOrgs.bind(this);
        // this.addObject = this.addObject.bind(this);

    }

    componentDidMount(){
      this.pullOrgs();
    }
  
    componentWillUnmount(){
    }

    async pullOrgs(){
      this.setState({loading:true});
  
      let organizations = await HTTPService.getOrganizations(this.props.history);
      console.log(organizations);
  
      // // filter out non-dealers
      // let dealers = organizations.filter((org)=>org.is_dealer);
  
      this.setState({organizations:organizations,loading:false});
    }

    // async addObject(json,history){
    //   json = {
    //     ...json,
    //     is_dealer:true,
    //   };
    //   return HTTPService.newOrganization(json,history);
    // }

    render(){

        return (
          <CRUDForm 
          history={this.props.history}
          edit={this.props.edit}
          editObjectId={this.props.editObjectId}
          editGetFunction={HTTPService.getUser}
          postFunction={HTTPService.newUser}
          putFunction={HTTPService.editUser}
          showDelete={true}
          deletionFunction={HTTPService.deleteUser}
          submissionSuccessRedirectFunction={this.props.selfEdit ? ()=>{}:()=>this.props.history.push(Navigation.paths.chart)}
          deletionSuccessRedirectFunction={()=>this.props.history.push(Navigation.paths.chart)}
          deleteDialogTitle={"Delete User?"}
          deleteDialogBody={"This cannot be undone."}
          formSchema={[
            {
              type:"row",
              items:[
                {
                  type:"text",
                  fieldName:"first_name",
                  title:"First",
                  path:"user.first_name",
                  isRequired:true,
                  maxLength:254,
                },
                {
                  type:"text",
                  fieldName:"last_name",
                  title:"Last",
                  path:"user.last_name",
                  isRequired:true,
                  maxLength:254,
                },
              ],
            },
            {
              type:"email",
              fieldName:"email",
              title:"Email",
              path:"user.email",
              isRequired:true,
              maxLength:254,
            },
            {
              type:"select",
              fieldName:"organization",
              title:"Organization",
              path:"organization.id",
              disabled:this.props.edit,
              isRequired:true,
              options:this.state.organizations.map((org)=>({
                name:org.name,
                value:org.id,
              })),
              hidden:this.props.selfEdit,
            }, 
            {
              type:"password",
              fieldName:"password",
              title:"Default Password",
              isRequired:true,
              minLength:8,
              remove:this.props.edit,
            },
            {
              type:"checkbox",
              fieldName:"is_admin",
              title:"Admin",
              hidden:this.props.selfEdit,
            },
            {
              type:"checkbox",
              fieldName:"is_dealer",
              title:"Dealer",
              value:true,
              hidden:true,
            },
            {
              type:"checkbox",
              fieldName:"is_super",
              title:"Super",
              value:false,
              hidden:true,
            },
            {
              type:"checkbox",
              fieldName:"is_subscriber",
              title:"Subscriber",
              value:false,
              hidden:true,
            },
            {
              type:"checkbox",
              fieldName:"is_builder",
              title:"Builder",
              value:false,
              hidden:true,
            },
            {
              type:"checkbox",
              fieldName:"is_deliverer",
              title:"Deliverer",
              value:false,
              hidden:true,
            },
          ]}
          />
        );
    }
}


export {UserForm};