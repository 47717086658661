import React from 'react';
import PropTypes from 'prop-types';
import HTTPService from "services/http.service";
import {Toast} from "services/toast.service";
import {PrimaryButton, 
  CircleButton, 
  Loader, 
  ConfirmDialog, 
  InputDialog,
  CustomTextArea,
  EmailInput,
  PasswordInput,
  InputErrorMessage,
  FlexBox,
  FlexRowBetweenStretch,
  FlexColumnBetweenStretch,
  FlexRowBetweenCenter,
  FlexColumnBetweenCenter,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  FlexRowStartCenter,
  FlexColumnStartCenter,
  FlexRowEndStretch,
  FlexColumnEndStretch,
  FlexRowEndCenter,
  FlexColumnEndCenter,
  FlexRowAroundStretch,
  FlexColumnAroundStretch,
  FlexRowAroundCenter,
  FlexColumnAroundCenter,
  Card,
  DataTable,
} from "components";
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";
import { 
  Typography, 
  Button, 
  Container,
  Grid,
  Box,
 } from '@material-ui/core';
import { FlexRowCenterStretch } from '../../components';
import MaterialTable from 'material-table'

class Sales extends React.Component{
  static propTypes={
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:false,
      sales:SALES,
      showConfirmDialog:false,
      showInputDialog:false,
      dialogInput:"",
    };

    this.pullSales = this.pullSales.bind(this);
    this.addItem = this.addItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }
  
  componentDidMount(){
    this.pullSales();
    
  }

  componentWillUnmount(){
  }

  async pullSales(){
    this.setState({loading:true});
    // return new Promise(resolve => setTimeout(() => resolve(user), 3000));
    await new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve();
      }, 500);
    });
    let sales = SALES;
    // sales = sales.map((sale)=>{
    //   return {
    //     ...sale,
    //     amount:sale.amount/100,
    //   };
    // });


    this.setState({sales:sales,loading:false});
  }

  async addItem(newData){
    if(!newData.email || !newData.item || isNaN(newData.amount)){
      throw Error("invalid");
    }
    this.setState({loading:true});
    await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
      }, 500);
    });  
    let sales = [
      ...this.state.sales,
      {
        ...newData,
        id:Math.floor(Math.random() * Math.floor(1000000)),
        datetime: (new Date()).toISOString(),
      },
    ]; 
    Toast.success("Sale Added!");
    this.setState({sales:sales,loading:false});
  }

  async updateItem(newData, oldData){
    if(!newData.email || !newData.item || isNaN(newData.amount)){
      throw Error("invalid");
    }
    this.setState({loading:true});
    await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
      }, 500);
    });  
    // let sales = this.state.sales.filter(sale=>sale.id !==oldData.id);
    let sales = this.state.sales.map(sale=>{
      if(sale.id ===oldData.id){
        return newData;
      }else{
        return sale;
      }      
    
    });
    Toast.success("Sale Updated!");
    this.setState({sales:sales,loading:false});
  }

  async deleteItem(oldData){
    this.setState({loading:true});
      await new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve();
      }, 500);
    });
    let sales = this.state.sales.filter(sale=>sale.id!==oldData.id);
    Toast.warning("Sale Deleted.");
    this.setState({sales:sales,loading:false});
  }

  render(){
    return (
      <FlexRowCenterStretch style={{
        padding:"40px",
      }}>
        <Container maxWidth={"lg"}>
          <FlexColumnStartStretch>
            <MaterialTable 
                title={"Sales"}
                columns={[
                  { title: "Time", field: "datetime", defaultSort:"desc", type:"datetime", editable:"never"},
                  { title: 'Customer', field: 'email', emptyValue:"None"},
                  { title: 'Item', field: 'item', emptyValue:"None"},
                  { title: 'Amount', field: 'amount', emptyValue:"None", type:"currency", headerStyle: {textAlign:"right",}},
                  // { title: 'Street', field: 'street_address', emptyValue:"None" },
                  // { title: 'City', field: 'city', emptyValue:"None" },
                  // { title: 'State', field: 'state', emptyValue:"None", cellStyle:{textTransform :"uppercase"} },
                  // { title: 'Zipcode', field: 'zipcode',  emptyValue:"None" },
                ]}
                data={this.state.sales}                
                showRowEditButton={true}
                onEditButtonClick={(event, rowData)=>this.props.history.push(Navigation.generateDealerEditPath(rowData.id))}
                isLoading={this.state.loading}
                addPrimaryAddButton={true}
                onAddButtonClick={()=>{}}
                onAddButtonText={"Add Sale"}
                options={{
                  actionsColumnIndex:-1,
                  exportButton:true,
                  addRowPosition:"first",
                }}
                editable={{
                  isEditable: rowData => true, // only name(a) rows would be editable
                  isDeletable: rowData => true, // only name(a) rows would be deletable
                  onRowAdd: newData =>this.addItem(newData),
                  onRowUpdate: (newData, oldData) => this.updateItem(newData, oldData),
                  onRowDelete: oldData => this.deleteItem(oldData),
              }}
            
            />
              {/* <DataTable
              /> */}
          </FlexColumnStartStretch>
        </Container>
      </FlexRowCenterStretch>
    );
  }
}


export default withRouter(Sales);
let now = new Date();

let twoMinutesAgo = new Date(now);
twoMinutesAgo.setMinutes(now.getMinutes() - 2);

let twoHoursAgo = new Date(twoMinutesAgo);
twoHoursAgo.setHours(twoMinutesAgo.getHours() - 2);

let fiveHoursAgo = new Date(twoHoursAgo);
fiveHoursAgo.setHours(twoHoursAgo.getHours() - 5);

let twoDaysAgo = new Date(fiveHoursAgo);
twoDaysAgo.setDate(fiveHoursAgo.getDate() - 2);

let tenDaysAgo = new Date(twoHoursAgo);
tenDaysAgo.setDate(twoHoursAgo.getDate() - 10);


// let twoWeeksAgo= new Date();

let SALES = [
  {
    id:23478,
    datetime:now.toISOString(),
    sku:230941383,
    item:"Basketball",
    amount:236.53,
    email:"jdoe@example.com",
  },
  {
    id:230984,
    datetime: twoHoursAgo.toISOString(),
    sku:230941383,
    item:"Baseball",
    amount:0.36,
    email:"dsmith@example.com",
  },
  {
    id:235742,
    datetime: twoDaysAgo.toISOString(),
    sku:230941383,
    item:"Soccerball",
    amount:2365.53,
    email:"mjames@example.com",
  },
  {
    id:64984984,
    datetime: tenDaysAgo.toISOString(),
    sku:24568148,
    item:"Tennis Ball",
    amount:12.96,
    email:"rowens@example.com",
  },
  {
    id:56198191,
    datetime: tenDaysAgo.toISOString(),
    sku:24568148,
    item:"Golf Ball",
    amount:12.96,
    email:"grojas@example.com",
  },
  {
    id:56198198,
    datetime: fiveHoursAgo.toISOString(),
    sku:24568148,
    item:"Hockey Puck",
    amount:12.96,
    email:"bneilson@example.com",
  },
];
