import React from 'react';
import HTTPService from "services/http.service";
import {deepGet,sortObjectListInPlace} from "services/helpers.service";
import Toast from "services/toast.service";
import {PrimaryButton, 
  CircleButton, 
  Loader, 
  ConfirmDialog, 
  InputDialog,
  CustomTextArea,
  EmailInput,
  PasswordInput,
  InputErrorMessage,
  FlexBox,
  FlexRowBetweenStretch,
  FlexColumnBetweenStretch,
  FlexRowBetweenCenter,
  FlexColumnBetweenCenter,
  FlexRowStartStretch,
  FlexColumnStartStretch,
  FlexRowStartCenter,
  FlexColumnStartCenter,
  FlexRowEndStretch,
  FlexColumnEndStretch,
  FlexRowEndCenter,
  FlexColumnEndCenter,
  FlexRowAroundStretch,
  FlexColumnAroundStretch,
  FlexRowAroundCenter,
  FlexColumnAroundCenter,
  Card,
} from "components";
import PropTypes from 'prop-types';
import Navigation from "services/navigation.service.js";
import { 
  withRouter,
} from "react-router-dom";

class DealerDetail extends React.Component{
  static propTypes={
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state={
      loading:true,
      dealer:null,
      users:[],
      showConfirmDialog:false,
      showInputDialog:false,
      dialogInput:"",
    };

    this.updateDealer = this.updateDealer.bind(this);
  }


  componentDidMount(){
    let id = Navigation.getURLParam(this.props,"id");
    this.updateDealer(id);
  }

  componentWillUnmount(){
  }

  async updateDealer(id){
    this.setState({loading:true});
    
    let dealer = await HTTPService.getOrganization(id,this.props.history);
    let users = await HTTPService.getUsers(this.props.history);
    users = users.filter((user)=>user.organization===dealer.id);
    sortObjectListInPlace(users,"user.last_name");
    this.setState({dealer:dealer,users:users,loading:false});
  }

  render(){
    return (
      this.state.loading ? <Loader/>:
        <FlexColumnStartStretch>
        <h1 style={{
          textAlign:"center",
        }}>
        {this.state.dealer.name}
        </h1>
        <FlexRowEndStretch style={{
          padding:"0px 50px"
        }}>
            <div style={{
              width:"200px"
            }}>
              <PrimaryButton>
                Add User
              </PrimaryButton>  
            </div>
          </FlexRowEndStretch>
          <FlexColumnStartCenter>
            <FlexColumnStartStretch>
              <FlexRowStartStretch>
                <div style={{
                  marginRight:"20px"
                }}>
                  Address:
                </div>
                <div>
                  {this.state.dealer.street_address || "None"}
                </div>
              </FlexRowStartStretch>
              <FlexRowStartStretch>
                <div style={{
                  marginRight:"20px"
                }}>
                  City:
                </div>
                <div>
                  {this.state.dealer.city || "None"}
                </div>
              </FlexRowStartStretch>
              <FlexRowStartStretch>
                <div style={{
                  marginRight:"20px"
                }}>
                  State:
                </div>
                <div>
                  {this.state.dealer.state || "None"}
                </div>
              </FlexRowStartStretch>
              <FlexRowStartStretch>
                <div style={{
                  marginRight:"20px"
                }}>
                  Zipcode:
                </div>
                <div>
                  {this.state.dealer.zipcode || "None"}
                </div>
              </FlexRowStartStretch>
            </FlexColumnStartStretch>
            <h3 style={{
              marginTop:"30px",
            }}>
              Users
            </h3>
            {this.state.users.map((user)=>
              <Card 
              key={user.firebase_id}
              style={{
                margin:"20px 40px",
              }}
              // onClick={()=>this.props.history.push(Navigation.generateDealerDetailPath(dealer.id))}
              disableHover={true}
              >
                <FlexRowStartCenter style={{
                    width:"700px",
                    height:"40px",
                }}>
                  <div>                  
                    {user.user.first_name}
                  </div>
                  <div  style={{
                    marginLeft:"10px",
                  }}>                  
                    {user.user.last_name}
                  </div>
                  <div  style={{
                    marginLeft:"10px",
                  }}>                  
                    {user.user.email}
                  </div>
                  {user.is_admin &&
                    <div  style={{
                      marginLeft:"auto",
                      padding:"5px",
                      backgroundColor:"#e66262",
                      borderRadius:"12px",
                    }}>                  
                      Admin
                    </div>
                  }
                </FlexRowStartCenter>
              </Card>
              )
            }
          </FlexColumnStartCenter>
      </FlexColumnStartStretch>
    );
  }
}


export default withRouter(DealerDetail);
