import { createMuiTheme } from '@material-ui/core';
import purple from '@material-ui/core/colors/purple';
class Theme{
    static fontFamily="'Jura', sans-serif";
    static secondaryFontFamily="'Roboto', sans-serif";
    static primaryButtonColor="#FF6978";
    static buttonFontFamily="'Arial'";
    static color1="#273469";
    static color2="#1E2749";
    static dangerColor="#ff2d2c";
    static fontColor1="#4c4c4c";
    static primaryColor= "#1C0A3C";
    static secondaryColor= "#EF2D79";
    static errorColor= "#f44336";
    static warningColor= "#ff9800";
    static infoColor= "#2196f3";
    static successColor= "#4caf50";
    static materialTheme=createMuiTheme({
        // Customize Pallete: https://material-ui.com/customization/palette/
        // Selecting Color: https://material-ui.com/customization/color/#color-tool
        palette: {
            type:"light",
            primary: {
                main: Theme.primaryColor,
            },
            secondary: {
                main: Theme.secondaryColor,
            },
            error:{
                main:Theme.errorColor,
            },
            warning:{
                main:Theme.warningColor,
            },
            info:{
                main:Theme.infoColor,
            },
            success:{
                main:Theme.successColor,
            },
        },
        typography: {
            // https://material-ui.com/customization/typography/

            h1:{
                fontFamily: "'Jura', sans-serif",
                fontWeight: 600,
                fontSize: "2rem",
            },
            h2:{},
            h3:{},
            h4:{},
            h5:{
                fontFamily: "'Jura', sans-serif",
                fontSize: "1.75rem",
            },
            h6:{},
            subtitle1:{},
            subtitle2:{},
            body1:{},
            body2:{},
            button:{
                fontFamily: "'Roboto', sans-serif",
                textTransform:"uppercase",
            },
            caption:{},
            overline:{},
        },
        // 8 is default: https://material-ui.com/customization/spacing/
        spacing: 8,
        // Override Props
        props: {
            MuiButtonBase: {
                disableRipple: true,
            },
        },
        // Override CSS: https://material-ui.com/customization/globals/#css
        overrides: {
            // Stylesheet Name
            MuiButton: {
                // rule
                contained: {
                    // variations
                },
            },
            MuiToolbar:{
                root:{
                    // backgroundColor:Theme.primaryColor,
                }
            },
            MuiDrawer:{
                paper:{
                    backgroundColor: Theme.primaryColor,
                    color:"white",
                    "& .MuiDivider-root": {
                        backgroundColor: "currentColor",
                        opacity: 0.3
                    },
                    "& .MuiIconButton-root": {
                        color: "inherit"
                    },                                   
                }
            },
            // MuiToggleButton:{
            //     root:{
            //         MuiSelected:{
            //             backgroundColor:"red",
            //         },
            //     },
            // },
        },
    });
}

export default Theme;